import ApiService from "./ApiService";

export default class CustomerService extends ApiService {
  public static async getCustomerLocation({
    customerLocationId,
    httpClient,
  }: {
    customerLocationId: string;
    httpClient: any;
  }): Promise<object | null> {
    let response = null;
    try {
      response = await httpClient.sendRequest({
        url: `/owner/customerlocation/${customerLocationId}`,
        method: "get",
        body: {
          id: customerLocationId,
        },
        headers: {
          headers: { "Content-Type": "application/json" },
          // withCredentials: true
        },
      });
      
      console.log(response);
    
    } catch (error) {
      console.log(error);
    }
    return response;
  }

  //   getAggregatedCustomerLocation = async (customerLocationId: String) => {
  //     try {
  //       if (customerLocationId) {
  //         console.log(
  //           `Searching for customer location with id: ${customerLocationId}`
  //         );

  //         const aggregatedResponse = await getCustomerLocationClient.sendRequest({
  //           url: "/owner/customerlocation/getaggregatedbyid",
  //           method: "post",
  //           body: {
  //             locationId: customerLocationId,
  //           },
  //           headers: {
  //             headers: { "Content-Type": "application/json" },
  //             // withCredentials: true
  //           },
  //         });

  //         console.log("customer location aggregated");
  //         console.log(aggregatedResponse);

  //         if (aggregatedResponse) {
  //           setCustomerLocation(aggregatedResponse[0].customerLocationDetails);
  //           setProvince(aggregatedResponse[0].provinceDetails);
  //           setCity(aggregatedResponse[0].cityDetails);
  //         } else {
  //         }
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
}
