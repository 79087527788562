import React, { useEffect, useMemo, useState } from "react";
import { useHttpClient } from "../../Hooks/HttpHook";
import SnackbarErrorClient from "../SnackbarError/SnackbarErrorClient";
import { Alert, Box, Modal } from "@mui/material";
import FormInputText from "../FormInputText/FormInputText";
import CustomButton from "../CustomButton/CustomButton";
import PageLoader from "../PageLoader";
import "../ReviewOrEditProduct/ReviewProduct.scss";
import { useForm } from "react-hook-form";
import { ICategory } from "../../Models/ICategory";
import { Cropper, getCroppedImg } from "react-cropper-custom";
import ImageUpload from "../SingleImageUpload/SingleImageUpload";
import imageCompression from "browser-image-compression";
import { IVendor } from "../../Models/IVendor";
import { IAdSection } from "../../Models/IAdSection";
import { IDiscount } from "../../Models/IDiscount";
import IconUploadSelector from "../IconUploadSelector.tsx/IconUploadSelector";
import SingleImageUpload from "../SingleImageUpload/SingleImageUpload";
import useSingleImageUploadManager from "../../Hooks/useSingleImageUploadManager";

type Area = {
  width: number;
  height: number;
  x: number;
  y: number;
};

interface AddUpdateAdSectionProps {
  adSection?: IAdSection | null;

  open: any;
  setOpen: any;
  getAdSections: any;
}

const AddUpdateAdSection: React.FunctionComponent<AddUpdateAdSectionProps> = (
  props
) => {
  const [error, setError] = useState("");
  const handleClose = () => props.setOpen(false);
  const [selectedOption, setSelectedOption] = useState<"product" | "other">(
    "product"
  );

  const {
    image,
    imageSrc,
    initImageSrc,
    zoom,
    uploadNewImage,
    setImage,
    setImageSrc,
    setInitImageSrc,
    setZoom,
    toggleUploadNewImage,
    handleImageUpload,
    handleIconUploadChange
  } = useSingleImageUploadManager();


  const {
    image: imageAr,
    imageSrc: imageSrcAr,
    initImageSrc: initImageSrcAr,
    zoom: zoomAr,
    uploadNewImage: uploadNewImageAr,
    setImage: setImageAr,
    setImageSrc: setImageSrcAr,
    setInitImageSrc: setInitImageSrcAr,
    setZoom: setZoomAr,
    toggleUploadNewImage: toggleUploadNewImageAr,
    handleImageUpload: handleImageUploadAr,
    handleIconUploadChange: handleIconUploadChangeAr
  } = useSingleImageUploadManager();

  const submitAdSectionClient = useHttpClient();

  let defValues = {};

  useEffect(() => {
    setError("");

    // if (props.product) {
    //   console.log(props.product);
    defValues = {};
    setImage({ preview: "", data: "" });
    setImageAr({ preview: "", data: "" });

    setImageSrc(null);
    setImageSrcAr(null);
    reset(defValues);
    // } else {
    // }
    console.log("hoooooooo");
  }, [props]);

  const { register, reset, handleSubmit, control, getValues } = useForm({
    defaultValues: useMemo(() => {
      return defValues;
    }, [props]),
  });

  const onSubmit = async () => {
    const values = control._formValues;

    try {
      let formData = new FormData();

      submitAdSectionClient.setLoading(true);

      if (imageSrc != null) {
        const compressedFile = await imageCompression(imageSrc, {
          maxSizeMB: 1.75,
          maxWidthOrHeight: 1000,
          useWebWorker: true,
        });

        formData.append("image", compressedFile);
      }

      if (imageSrcAr != null) {
        const compressedFileAr = await imageCompression(imageSrcAr, {
          maxSizeMB: 1.75,
          maxWidthOrHeight: 1000,
          useWebWorker: true,
        });
        formData.append("imageAr", compressedFileAr);
      }

      submitAdSectionClient.setLoading(false);

      if (values.name) {
        formData.append("name", values.name);
      }

      if (values.nameAr) {
        formData.append("nameAr", values.nameAr);
      }

      if (selectedOption === "product") {
        if (values.productId) {
          formData.append("product", values?.productId);
        }
      }

      if (values.vendorId && selectedOption !== "product") {
        formData.append("vendor", values?.vendorId);
      }

      if (values.discountId && selectedOption !== "product") {
        formData.append("discount", values?.discountId);
      }

      if (values.categoryId && selectedOption !== "product") {
        formData.append("category", values?.categoryId);
      }

      console.log(`AddUpdateAdSection - onSubmit - outputting formData`);
      formData.forEach((value, key) => {
        console.log(`\t${key}: ${value}`);
      });

      console.log(`AddUpdateAdSection - onSubmit - outputting values`);
      console.log(values);

      // If editing an existing ad section
      if (props.adSection) {

        formData.append("id", props.adSection.id ?? "");

        console.log("formData images: ", formData.get("images"));

        const url = "/owner/homesection/ad";

        const response = await submitAdSectionClient.sendRequest({
          url: url,
          method: "put",
          body: formData,
          headers: {
            headers: { "Content-Type": "multipart/form-data" },
            // withCredentials: true
          },
        });

        // If Creating a new ad section
      } else {
        
        const url = "/owner/homesection/ad";

        const response = await submitAdSectionClient.sendRequest({
          url: url,
          method: "post",
          body: formData,
          headers: {
            headers: { "Content-Type": "multipart/form-data" },
            // withCredentials: true
          },
        });
      }

      props.setOpen(false);
      props.getAdSections();
    } catch (e) {
      console.log(e);
      submitAdSectionClient.setLoading(false);
    }
  };

  const toggleOption = (option: "product" | "other") => {
    setSelectedOption(option);
    if (option === "product") {
      reset({ ...defValues, productId: "" });
    } else {
      reset({ ...defValues, vendorId: "", discountId: "", categoryId: "" });
    }
  };

  return (
    <div>
      <SnackbarErrorClient client={submitAdSectionClient} />
      <PageLoader loading={submitAdSectionClient.isLoading} />

      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="reviewProductBox">
          <p className="pageTitle">
            {props.adSection ? "Edit" : "Create"} {props.adSection?.name ?? ""}{" "}
            Ad Section
          </p>

          <form className="reviewProduct" onSubmit={handleSubmit(onSubmit)}>
            <FormInputText
              rules={{
                required: true,
              }}
              value={props.adSection?.name}
              control={control}
              name="name"
              label={"name"}
            />
            <FormInputText
              rules={{
                required: true,
              }}
              control={control}
              value={props.adSection?.nameAr}
              name="nameAr"
              label={"Name Arabic"}
            />
            {/* <input type="file" name="file" title="Main I" onChange={handleFileChange}></input> */}
            <div>
              <label>
                <input
                  type="radio"
                  value="product"
                  checked={selectedOption === "product"}
                  onChange={() => toggleOption("product")}
                />
                Product ID
              </label>
              <label>
                <input
                  type="radio"
                  value="other"
                  checked={selectedOption === "other"}
                  onChange={() => toggleOption("other")}
                />
                Vendor / Discount / Category ID
              </label>
            </div>
            {selectedOption === "product" ? (
              <FormInputText
                rules={{}}
                control={control}
                name="productId"
                value={props.adSection?.product}
                label={"Product ID"}
              />
            ) : (
              <>
                <FormInputText
                  rules={{}}
                  control={control}
                  name="vendorId"
                  value={props.adSection?.vendor}
                  label={"Vendor ID"}
                />
                <FormInputText
                  rules={{}}
                  control={control}
                  name="discountId"
                  value={props.adSection?.discount}
                  label={"Discount ID"}
                />
                <FormInputText
                  rules={{}}
                  control={control}
                  name="categoryId"
                  value={props.adSection?.category}
                  label={"Category ID"}
                />
              </>
            )}

            <IconUploadSelector
              uploadNewImage={uploadNewImage}
              handleIconUploadChange={handleIconUploadChange}
            />

            {/* Show existing image */}

            {/* Show message if image url exists */}
            {!uploadNewImage &&
              (props.adSection?.image ? (
                <p>Existing image found.</p>
              ) : (
                <p>No existing image found</p>
              ))}

            {!uploadNewImage && (
              <img width={90} height={90} src={props.adSection?.image} />
            )}

            {/* Upload new image */}
            {uploadNewImage && (
              <SingleImageUpload
                title={"Image EN"}
                img={initImageSrc}
                setImg={setImageSrc}
                aspectRatio={16 / 9}
              />
            )}

            {/* Arabic image upload */}
            <IconUploadSelector
              title="Section Image (AR)"
              uploadNewImage={uploadNewImageAr}
              handleIconUploadChange={handleIconUploadChangeAr}
            />

            {!uploadNewImageAr && (
              <>
                {props.adSection?.imageAr ? (
                  <p>Existing Arabic image found.</p>
                ) : (
                  <p>No existing Arabic image found</p>
                )}
                <img width={90} height={90} src={props.adSection?.imageAr} />
              </>
            )}

            {uploadNewImageAr && (
              <SingleImageUpload
                title={"Image AR"}
                img={initImageSrcAr}
                setImg={setImageSrcAr}
                aspectRatio={16 / 9}
              />
            )}

            {error && <Alert severity="error">{error}</Alert>}

            <CustomButton
              text={props.adSection ? "Edit" : "Create"}
              onClick={handleSubmit(onSubmit)}
            />
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default AddUpdateAdSection;
