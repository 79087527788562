import * as XLSX from 'xlsx';

class ProductExcelImportHelper {

    static async getJSONFromExcelFile(): Promise<any[]> {
        try {
            const file = await this.selectExcelFile();
            const data = await this.readExcelFile(file);
            return this.processExcelDataToJSON(data);
        } catch (error) {
            console.log('Error processing file:', error);
            throw error;
        }
    }
    
    private static async selectExcelFile(): Promise<File> {
        return new Promise<File>((resolve, reject) => {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = '.xlsx, .xls';
    
            input.onchange = () => {
                const target = input as HTMLInputElement;
                const file = target.files?.[0];
                if (file) {
                    resolve(file);
                } else {
                    reject(new Error('No file selected'));
                }
            };
    
            input.click();
        });
    }
    
    private static async readExcelFile(file: File): Promise<Uint8Array> {
        return new Promise<Uint8Array>((resolve, reject) => {
            const reader = new FileReader();
            
            reader.onload = (e: ProgressEvent<FileReader>) => {
                resolve(new Uint8Array(e.target?.result as ArrayBuffer));
            };
            
            reader.onerror = (error) => {
                reject(new Error('Failed to read file: ' + error));
            };
            
            reader.readAsArrayBuffer(file);
        });
    }
    
    private static processExcelDataToJSON(data: Uint8Array): any[] {
        const workbook = XLSX.read(data, { type: 'array' });
        const allSheetData: any[] = [];
    
        workbook.SheetNames.forEach(sheetName => {
            const sheet = workbook.Sheets[sheetName];
            const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 }) as (string | number)[][];
    
            if (sheetData.length === 0) {
                console.log(`No data found in sheet: ${sheetName}`);
                return;
            }
    
            const headers = sheetData[0] as string[];
            const dataObjects = sheetData.slice(1).map((row: (string | number)[]) => {
                const obj: Record<string, string | number> = {};
                headers.forEach((header, index) => {
                    obj[header] = row[index];
                });
                return obj;
            });
    
            allSheetData.push({ sheetName, data: dataObjects });
        });
    
        // console.log("Transformed Data from all sheets:", allSheetData);
        return allSheetData;
    }
      
      static async getProductsDataFromJSON (excelData: any[]) {
    
        const detailsSheet = excelData.find(sheet => sheet.sheetName === "details")?.data || [];
        const imagesSheet = excelData.find(sheet => sheet.sheetName === "images")?.data || [];
        const sizesSheet = excelData.find(sheet => sheet.sheetName === "sizes")?.data || [];
      
        const products: any[] = [];
      
        detailsSheet.forEach((detailRow: any) => {

          if (detailRow.product_id) {
            
            console.log(detailRow);

            const productData = {
              name: detailRow.name || '',
              price: detailRow.price || 0,
              description: detailRow.description || '',
              category: detailRow.category || '',
              vendor: detailRow.vendor || '',
              sku: detailRow.sku || '',
              images: [] as Array<any>,
              outOfStock: detailRow.outOfStock || false,
              isDeleted: detailRow.isDeleted || false,
              isHidden: detailRow.isHidden || false,
              status: 'accepted', 
              unitsSold: 0,
              sizeType: detailRow.sizeType || '',
              sizes: [] as Array<any>
            };
      
            imagesSheet.forEach((imageRow: any) => {
              if (imageRow.product_id === detailRow.product_id) {
                productData.images.push(imageRow.image_url);
              }
            });
      
            const productSizes: { [key: string]: any } = {};
    
            sizesSheet.forEach((sizeRow: any) => {
              if (sizeRow.product_id === detailRow.product_id) {
                if (!productSizes[sizeRow.sizeText]) {
                  productSizes[sizeRow.sizeText] = {
                    sizeType: sizeRow.sizeType,
                    sizeText: sizeRow.sizeText,
                    size: sizeRow.size,
                    colorQuantity: []
                  };
                }
    
                productSizes[sizeRow.sizeText].colorQuantity.push({
                  color: sizeRow.color,
                  quantity: sizeRow.quantity
                });
              }
            });
      
            productData.sizes = Object.values(productSizes);
            
            products.push(productData);
    
          }
        });
        console.log("getProductDataAsJSON — retrieved product data from excel sheet");
        console.log(products);
        return products;
      };
    
}


export default ProductExcelImportHelper;