import React, { ReactNode } from "react";
import SnackbarErrorClient from "../Components/SnackbarError/SnackbarErrorClient";

export const BasePage = ({
  children,
  snackBarErrorClients = [],
}: {
  children: ReactNode;
  snackBarErrorClients?: any[];
}) => {
  return (
    <div className="page">
      <div>
        {snackBarErrorClients.map((httpClient: any, index: any) => (
          <SnackbarErrorClient client={httpClient} key={index} />
        ))}
      </div>
      {children}
    </div>
  );
};
