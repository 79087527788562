import React from "react";
import { Typography, TextField, Button, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";


interface CustomFormInputListProps {
  title: string;
  items: any[];
  newItem: string;
  onNewItemChange: (value: string) => void;
  onAddItem: () => void;
  onRemoveItem: (index: number) => void;
}

const CustomFormInputList: React.FC<CustomFormInputListProps> = ({
  title,
  items,
  newItem,
  onNewItemChange,
  onAddItem,
  onRemoveItem,
}) => {
  return (
    <div>
      <Typography variant="h6">{title}</Typography>
      <div style={{ display: "flex"}}>
      <TextField 
        id={title.toLowerCase()}
        label={`Add ${title} ID`}
        value={newItem}
        onChange={(e) => onNewItemChange(e.target.value)}
        style={{ width: "100%"}}
      />
      <Button variant="contained" onClick={onAddItem}>
        Add
      </Button>  
      </div>
      
      <div className="inline-list" style={{ display: "flex", alignItems: "center" }}>
        {items.map((item, index) => (
          <div key={index} style={{ display: "flex", alignItems: "center" }}>
            <Typography
              className="inline-item"
              variant="body1"
              style={{ margin: "8px", marginTop: "12px" }}
            >
              {item}
              {item?.name ? `${item.name} | ` : ""}
              {item?.id ? `${item.id} | ` : ""}
              {item}
            </Typography>
            <IconButton onClick={() => onRemoveItem(index)}> 
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomFormInputList;
