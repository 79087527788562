import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { useHttpClient } from "../../Hooks/HttpHook";
import SnackbarErrorClient from "../../Components/SnackbarError/SnackbarErrorClient";
import TableList from "../../Components/Table/Table";
import CustomButton from "../../Components/CustomButton/CustomButton";
import AddUpdateAdSection from "../../Components/AddUpdateAdSection/AddUpdateAdSection";
import { FaEdit, FaTrash } from "react-icons/fa";
import { IAdSection } from "../../Models/IAdSection";
import { MenuItem, Select } from "@mui/material";
import ImagePopup from "../../Components/ImagePopup/ImagePopup";
import DeletePopup from "../../Components/DeletePopup/DeletePopup";
import { useDeleteHandler  } from "../../Hooks/useDeleteHandler";
import { BasePage } from "../../Layout/BasePage";
import { PageTitle } from "../../Layout/PageTitle";
import { PageUpper } from "../../Layout/PageUpper";
import { useBasicDetails } from "../../Hooks/useBasicDetails";
import GenericPopup from "../../Components/GenericPopup/GenericPopup";

interface AdSectionListProps {}

const AdSectionList: React.FunctionComponent<AdSectionListProps> = () => {
  const [AdSections, setAdSections] = useState<[]>([]);
  const getAdSectionsClient = useHttpClient();
  const [openAddUpdateModal, setOpenAddUpdateModal] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageModalSrc, setImageModalSrc] = useState("");
  const [selectedAdSection, setSelectedAdSection] = useState<IAdSection | null>(
    null
  );
  const [count, setCount] = useState(0);

  const { openDeletePopup, setOpenDeletePopup, handleDeleteClick, deleteItem, deleteClient } = useDeleteHandler({
    requestUrl: "/owner/homesection/ad/delete",
    requestMethod: "post",
    onSuccess: async () => {
      setAdSections([]);
      await getAdSections();
    },
  });


  const {
    fetchBasicDetails: fetchCategoryBasicDetails,
    getBasicDetailsHttpClient: getCategoryBasicDetailsClient,
    basicDetailsData: categoryBasicDetailsData,
    openBasicDetailsPopup: openCategoryBasicDetailsPopup, 
    setOpenBasicDetailsPopup: setOpenCategoryBasicDetailsPopup
    } =
    useBasicDetails({
      baseUrl: "/owner/category/basic-details",
      method: "get"
  });

  const {
    fetchBasicDetails: fetchVendorBasicDetails,
    getBasicDetailsHttpClient: getVendorBasicDetailsHttpClient,
    basicDetailsData: vendorBasicDetailsData,
    openBasicDetailsPopup: openVendorBasicDetailsPopup, 
    setOpenBasicDetailsPopup: setOpenVendorBasicDetailsPopup
   } =
    useBasicDetails({
      baseUrl: "/owner/vendor/basic-details",
      method: "get"
    });


    const {
      fetchBasicDetails: fetchProductBasicDetails,
      getBasicDetailsHttpClient: getProductBasicDetailsClient,
      basicDetailsData: productBasicDetailsData,
      openBasicDetailsPopup: openProductBasicDetailsPopup, 
      setOpenBasicDetailsPopup: setOpenProductBasicDetailsPopup
      } =
      useBasicDetails({
        baseUrl: "/owner/product/basic-details",
        method: "get"
    });


    const {
      fetchBasicDetails: fetchDiscountBasicDetails,
      getBasicDetailsHttpClient: getDiscountBasicDetailsClient,
      basicDetailsData: discountBasicDetailsData,
      openBasicDetailsPopup: openDiscountBasicDetailsPopup, 
      setOpenBasicDetailsPopup: setOpenDiscountBasicDetailsPopup
      } =
      useBasicDetails({
        baseUrl: "/owner/discount/basic-details",
        method: "get"
    });

  useEffect(() => {
    if (openAddUpdateModal === false) {
      setSelectedAdSection(null);
    }
  }, [openAddUpdateModal, setOpenAddUpdateModal]);

  useEffect(() => {
    getAdSections();
  }, []);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 250 },
    {
      field: "Image",
      headerName: "Image",
      width: 90,
      renderCell: (params) =>
        params.row.image && (
          <img
            width={120}
            height={90}
            src={params.row.image}
            alt="logo"
            onClick={() => {
              console.log("opening image popup");
              setOpenImageModal(true);
              setImageModalSrc(params.row.image);
            }}
          />
        ),
    },
    {
      field: "imageAr",
      headerName: "Image AR",
      width: 90,
      renderCell: (params) =>
        params.row.imageAr && (
          <img
            width={120}
            height={90}
            src={params.row.imageAr}
            alt="logo"
            onClick={() => {
              console.log("opening image popup arabic");
              setOpenImageModal(true);
              setImageModalSrc(params.row.imageAr);
            }}
          />
        ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      editable: true,
    },
    {
      field: "nameAr",
      headerName: "Name AR",
      width: 100,
      editable: true,
    },
    {
      field: "product",
      headerName: "Product ID",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={async () => {
            await fetchProductBasicDetails(params.row.product);
            setOpenProductBasicDetailsPopup(true);
          }}
        >
          {params.row.product}
        </div>
      ),
    },
    {
      field: "category",
      headerName: "Category ID",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={async () => {
            await fetchCategoryBasicDetails(params.row.category);
            setOpenCategoryBasicDetailsPopup(true);
          }}
        >
          {params.row.category}
        </div>
      ),
    },
    {
      field: "vendor",
      headerName: "Vendor ID",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={async () => {
            await fetchVendorBasicDetails(params.row.vendor);
            setOpenVendorBasicDetailsPopup(true);
          }}
        >
          {params.row.vendor}
        </div>
      ),
    },
    {
      field: "discount",
      headerName: "Discount ID",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={async () => {
            await fetchDiscountBasicDetails(params.row.discount);
            setOpenDiscountBasicDetailsPopup(true);
          }}
        >
          {params.row.discount}
        </div>
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 50,
      renderCell: (params) => (
        <FaEdit
          onClick={() => {
            setSelectedAdSection(params.row);

            setOpenAddUpdateModal(true);
          }}
        />
      ),
    },
    {
      field: "isActive",
      headerName: "Active Status",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <Select
          value={params.row.isActive}
          onChange={async (e) => {
            console.log(
              `Updating active status. New status: ${e.target.value}`
            );
            await updateActiveStatus(params.row.id, e.target.value === "true");
            await getAdSections();
          }}
        >
          <MenuItem value="true">True</MenuItem>
          <MenuItem value="false">False</MenuItem>
        </Select>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 60,
      renderCell: (params) => (
        <FaTrash
          onClick={() => {
            handleDeleteClick(params.row.id);
          }}
        />
      ),
    },
  ];

  const getAdSections = async () => {
    try {
      const url = "/owner/homesection/ad";
      const response = await getAdSectionsClient.sendRequest({
        url: url,
        method: "get",
        body: null,
        headers: {
          headers: {
            "Content-Type": "application/json",
            //withCredentials: true
          },
        },
      });
      console.log(response);

        if (response && response.content) {
          // setAdSections(response.content);
          setAdSections(response.content);
          setCount(response.totalElements);
        }
      
    } catch (e) {
      console.log(e);
      console.log(getAdSectionsClient.error);
    }
  };

  const updateActiveStatus = async (id: any, isActive: any) => {
    try {
      const url = "/owner/homesection/ad/isactive";
      const response = await getAdSectionsClient.sendRequest({
        url: url,
        method: "put",
        body: { id, isActive, sectionType: "ad" },
        // body: null,
        headers: {
          headers: {
            "Content-Type": "application/json",
            //withCredentials: true
          },
        },
      });
      console.log(response);
    } catch (e) {
      console.log(e);
      console.log(getAdSectionsClient.error);
    }
  };


  return (
    <BasePage snackBarErrorClients={[getAdSectionsClient]}>
      
      <ImagePopup
        open={openImageModal}
        setOpen={setOpenImageModal}
        imageSrc={imageModalSrc}
      />

      <GenericPopup
        open={openCategoryBasicDetailsPopup}
        setOpen={setOpenCategoryBasicDetailsPopup}
        data={categoryBasicDetailsData || {}}
      />

      <GenericPopup
        open={openVendorBasicDetailsPopup}
        setOpen={setOpenVendorBasicDetailsPopup}
        data={vendorBasicDetailsData || {}}
      />

      <GenericPopup
        open={openProductBasicDetailsPopup}
        setOpen={setOpenProductBasicDetailsPopup}
        data={productBasicDetailsData || {}}
      />

      <GenericPopup
        open={openDiscountBasicDetailsPopup}
        setOpen={setOpenDiscountBasicDetailsPopup}
        data={discountBasicDetailsData || {}}
      />

      <DeletePopup
        open={openDeletePopup}
        setOpen={setOpenDeletePopup}
        deleteFunction={deleteItem}
        deleteHttpClient={deleteClient}
      />

      <AddUpdateAdSection
        open={openAddUpdateModal}
        adSection={selectedAdSection}
        setOpen={setOpenAddUpdateModal}
        getAdSections={getAdSections}
      />

      <PageUpper>
        <PageTitle title="Ad Sections" />
        <CustomButton
          text="Add Ad Section"
          onClick={() => {
            setOpenAddUpdateModal(true);
          }}
          isSecondary={true}
        />
      </PageUpper>

      <TableList
        rows={AdSections}
        isLoading={getAdSectionsClient.isLoading}
        columns={columns}
        rowsCount={count}
      />
    </BasePage>
  );
};

export default AdSectionList;
