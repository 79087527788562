import React, { useEffect, useState } from "react";
import { ICity } from "../../Models/ICity";
import { FaEdit, FaPlus } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa";
import { useHttpClient } from "../../Hooks/HttpHook";
import { GridColDef } from "@mui/x-data-grid";
import UpdateABSZone from "../../Components/UpdateABSZone/UpdateABSZone";
import TableList from "../../Components/Table/Table";
import SnackbarErrorClient from "../../Components/SnackbarError/SnackbarErrorClient";
import { IProvince } from "../../Models/IProvince";
import ProvincesList from "./ProvincesList";
import { BasePage } from "../../Layout/BasePage";

interface CitiesListProps {
    province:IProvince | null;
}


const CitiesList: React.FunctionComponent<CitiesListProps> = (props) => {
    console.log(props);

    useEffect(() => {
        getCitiesByProvince(props.province?.id);
      }, []);
    const [view, setView] = useState(0);
    const [cities, setCities] = useState<ICity[]>([]);
    const getCitiesClient = useHttpClient();
    const [cityId, setCityId] = useState("");
    const [zoneType, setZoneType] = useState("");
    const [openZoneModal, setOpenZoneModal] = useState(false);
    const columns: GridColDef[] = [
        { field: "id", headerName: "ID", width: 250 },
        {
          field: "name",
          headerName: "Name",
          width: 200,
          editable: true,
        },
        {
          field: "nameAr",
          headerName: "NameAr",
          width: 200,
          editable: true,
        },
        {
            field: "nameAr",
            headerName: "NameAr",
            width: 200,
            editable: true,
          },
          {
            field: "aramexZoneId",
            headerName: "Aramex Zone",
            width: 120,
            renderCell: (params) => (
              <>
                <p>{params.row.aramexZoneId}</p> &nbsp; &nbsp;
                {params.row.aramexZoneId ? (
                  <FaEdit
                    color="#4D4ABF"
                    onClick={() => {
                      console.log("hi");
                      setZoneType("aramex");
                      setCityId(params.row.id);
                      setOpenZoneModal(true);
                    }}
                  />
                ) : (
                  <FaPlus
                    color="#4D4ABF"
                    onClick={() => {
                      console.log("hi");
                      setZoneType("aramex");
    
                      setCityId(params.row.id);
                      setOpenZoneModal(true);
                    }}
                  />
                )}
              </>
            ),
          }
      ];


      const getCitiesByProvince = async (provinceId: String) => {
        try {
          const response = await getCitiesClient.sendRequest({
            url: "/owner/city",
            method: "post",
            body: {
              provinceId,
            },
            headers: {
              headers: { "Content-Type": "application/json" },
              // withCredentials: true
            },
          });
          console.log(response);
          if (response) {

            setCities(response.content);
          
          }
        } catch (e) {
          console.log(e);
          console.log(getCitiesClient.error);
        }
      };




      return(
        view == 0 ?
        <BasePage snackBarErrorClients={[getCitiesClient]}>

            <UpdateABSZone
                cityId={cityId}
                open={openZoneModal}
                setOpen={setOpenZoneModal}
                type={zoneType}
                onSubmit={() => {
                    getCitiesByProvince(props.province?.id);
                }}
            />

          <div
            style={{
              display: "flex",
              gap: "16px",
              alignItems: "center",
              justifyContent:"flex-start",
            }}
          >
            <FaArrowLeft onClick={()=>{
                setView(1);
            }} />
            <p style={{ marginBottom: 0 }} className="pageTitle">
              {props.province?.name} Cities
            </p>
          </div>
        
          <TableList
            rows={cities}
            isLoading={getCitiesClient.isLoading}
            columns={columns}
          />

        </BasePage>
        :
        <ProvincesList/>
      )
};

export default CitiesList;