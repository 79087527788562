import { useState, useEffect } from "react";
import { useHttpClient } from "./HttpHook";
import addQueryStringsToUrl from "../Utils/addQueryStringsToUrl";
import usePopupManager from "./usePopupManager";

interface useBasicDetailsProps {
  baseUrl: string;
  method: string;
}

export const useBasicDetails = (props: useBasicDetailsProps) => {
    
  const [basicDetailsData, setBasicDetailsData] = useState<any>(null);
  const [error, setError] = useState<Error | null>(null);
  const httpClient = useHttpClient();
  const [openBasicDetailsPopup, setOpenBasicDetailsPopup] = usePopupManager();

  const fetchBasicDetails = async (id: string) => {
    try {

      const baseUrl = props.baseUrl;

      let url = `${baseUrl}/${id}`

      const response = await httpClient.sendRequest({
        url: url,
        method: props.method,
        body: null
      });

      console.log(`useBasicDetails - fetchBasicDetails - response`);
      console.log(response);

      setBasicDetailsData(response);

    } catch (err) {
      setError(err as Error);
      setBasicDetailsData(null);
    }
  };

  return {
    getBasicDetailsHttpClient: httpClient,
    openBasicDetailsPopup,
    setOpenBasicDetailsPopup,
    fetchBasicDetails,
    basicDetailsData,
    setBasicDetailsData,
    error,
    isLoading: httpClient.isLoading,
  };
};
