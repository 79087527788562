import React, { useEffect, useState } from "react";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid";
import { useHttpClient } from "../../Hooks/HttpHook";

import SnackbarErrorClient from "../../Components/SnackbarError/SnackbarErrorClient";
import { FaExternalLinkAlt, FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import TableList from "../../Components/Table/Table";

import { Button, Menu, MenuItem, Select, Snackbar } from "@mui/material";
import OrderList from "../OrderList/OrderList";
import SearchBar from "../../Components/SearchBar/SearchBar";
import formatDate from "../../Utils/formatDate";
import { IS_NODE_BACKEND, PAGE_SIZE } from "../../Shared/globalVar";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { VendorTransactionFilterEnum } from "../../Data/Enums";
import usePaginationUtil from "../../Hooks/usePaginationUtil";
import useSearchBar from "../../Hooks/useSearchBar";
import { usePaginationManager } from "../../Hooks/usePaginationManager";
import ApiService from "../../Services/ApiService";
import { PageTitle } from "../../Layout/PageTitle";
import { BasePage } from "../../Layout/BasePage";
import { PageUpper } from "../../Layout/PageUpper";
import { useBasicDetails } from "../../Hooks/useBasicDetails";
import GenericPopup from "../../Components/GenericPopup/GenericPopup";

interface FinancialTransactionListProps {}

const FinancialTransactionList: React.FunctionComponent<
  FinancialTransactionListProps
> = (props: FinancialTransactionListProps) => {

  const updatePaymentStatusClient = useHttpClient();
  const [ displayVendorTransactionsByOrder, setDisplayVendorTransactionsByOrder] = useState(false);
  const [vendorTransactionIdOrdersFilter, setVendorTransactionIdOrdersFilter] = useState(undefined);

  const {
    items,
    setItems,
    dataGridApiRef,
    count,
    setCount,
    status,
    setStatus,
    httpClient
  } = usePaginationManager();


  const {
    searchBarResults,
    setSearchBarResults,
    getSearchBarItemById
  } = useSearchBar({
    getByIdRequestUrl: "/owner/vendortransaction",
    getByIdRequestMethod: "post",
    responseField: "content"
  });

  const {
    fetchBasicDetails: fetchOrderBasicDetails,
    getBasicDetailsHttpClient: getOrderBasicDetailsClient,
    basicDetailsData: orderBasicDetailsData,
    openBasicDetailsPopup: openOrderBasicDetailsPopup, 
    setOpenBasicDetailsPopup: setOpenOrderBasicDetailsPopup
    } =
    useBasicDetails({
      baseUrl: "/owner/order/basic-details",
      method: "get"
    });

  let vendorTransactionStatuses = [
    {
      name: "All",
      vendorTransactions: [],
      needsLoading: true,
      count: 0,
    },
    {
      name: VendorTransactionFilterEnum.Paid,
      // name: "Paid",
      vendorTransactions: [],
      needsLoading: true,
      count: 0,
    },
    {
      name: VendorTransactionFilterEnum.NotPaid,
      // name: "Not Paid",
      vendorTransactions: [],
      needsLoading: true,
      count: 0,
    },
    {
      name: VendorTransactionFilterEnum.Returned,
      // name: "Returned",
      vendorTransactions: [],
      needsLoading: true,
      count: 0,
    }
  ];

  useEffect(() => {
    getVendorTransactions(true, false);
  }, [status]);

  const columns: GridColDef[] = [
    { 
      field: "id", 
      headerName: "Transaction ID", 
      width: 240,
      editable: true},
    {
      field: "vendorName",
      headerName: "Vendor Name",
      width: 120,
      editable: true,
    },
    // {
    //   field: "topLevelCategoryName",
    //   headerName: "Category",
    //   width: 110,
    //   editable: true,
    // },
    {
      field: "orders",
      headerName: "Order Items",
      width: 90,
      editable: true,
      renderCell: (params) => (
        <FaExternalLinkAlt
          onClick={() => {
            setVendorTransactionIdOrdersFilter(params.row.id);
            setDisplayVendorTransactionsByOrder(true);
          }}
        />
      ),
    },
    // {
    //   field: "statusHistory",
    //   headerName: "Status History",
    //   width: 250,
    //   editable: false,
    // },
    {
      field: "orderId",
      headerName: "Order ID",
      width: 240,
      editable: true,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={async () => {
            await fetchOrderBasicDetails(params.row.orderId);
            setOpenOrderBasicDetailsPopup(true);
          }}
        >
          {params.row.orderId}
        </div>
      ),
    },
    // {
    //   field: "orderItemId",
    //   headerName: "Order Item ID",
    //   width: 240,
    //   editable: true,
    // },
    {
      field: "totalAmount",
      headerName: "Final Amount",
      width: 100,
      editable: true,
    },
    {
      field: "paymentMethod",
      headerName: "Payment Method",
      width: 140,
      editable: true,
    },
    {
      field: "subtotalAmount",
      headerName: "Subtotal Amount",
      width: 120,
      editable: false,
    },
    {
      field: "deliveryFees",
      headerName: "Delivery Fees",
      width: 100,
      editable: false,
    },
    {
      field: "amount",
      headerName: "Order Amount",
      width: 100,
      editable: true,
    },
    {
      field: "totalCommission",
      headerName: "Total Commission",
      width: 120,
      editable: true,
    },
    {
      field: "totalDiscount",
      headerName: "Total Discount",
      width: 100,
      editable: true,
    },
    {
      field: "totalDiscountOnFlagak",
      headerName: "Discount on Flagak",
      width: 140,
      editable: true,
    },
    {
      field: "totalDiscountOnVendor",
      headerName: "Discount on Vendor",
      width: 140,
      editable: true,
    },
    {
      field: "currency",
      headerName: "Currency",
      width: 60,
      editable: true,
    },
    {
      field: "isCanceled",
      headerName: "Is Cancelled",
      width: 100,
      editable: true,
    },
    {
      field: "returned",
      headerName: "Is Returned",
      width: 90,
      editable: true,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 170,
      editable: true,
      valueGetter: (params) => formatDate(params.value),
    },
    {
      field: "paid",
      headerName: "Payment Status",
      width: 130,
      editable: true,
      renderCell: (params) => (
        <Select
          value={params.row.paid}
          onChange={async (e) => {
            console.log(
              `Updating payment status. New status: ${e.target.value}`
            );
            await updatePaymentStatus(
              params.row.id,
              e.target.value
            );
            await getVendorTransactions(true, true);
          }}
          style={{
            backgroundColor:
              (params.row.paid) === true
                ? "rgba(144, 238, 144, 0.5)"
                : (params.row.paid) === false
                ? "rgba(255, 182, 193, 0.5)"
                : "",
          }}
        >
          <MenuItem value="true">Paid</MenuItem>
          <MenuItem value="false">Not Paid</MenuItem>
        </Select>
      ),
    },
  ];

  const updatePaymentStatus = async (
    transactionId: string,
    paymentStatus: string
  ) => {
    try {
      console.log("updatepaymentstatus request body: ");
      console.log({ transactionId, paymentStatus });


      const response = await updatePaymentStatusClient.sendRequest({
        url: `/owner/vendortransaction/paymentstatus`,
        method: "post",
        body: {
          transactionId,
          paymentStatus,
        },
        headers: {
          headers: { "Content-Type": "application/json" },
        },
      });

  
      console.log(`updating payment status`);
      console.log(response);

      // await getVendorTransactions(true);
    } catch (e) {
      console.log(updatePaymentStatusClient.error);
    } 
  };

  const getVendorTransactions = async (
    getCount: boolean,
    currentPageUpdated?: boolean
  ) => {

    await ApiService.getItems({
      status: status,
      statuses: vendorTransactionStatuses,
      getCount: getCount,
      items: items,
      setItems: setItems,
      setCount: setCount,
      httpClient: httpClient,
      dataGridApiRef: dataGridApiRef,
      requestUrl: "/owner/vendortransaction",
      requestMethod: "post",
      responseField: "content"
    });

  };

  return displayVendorTransactionsByOrder ? (
    // show the orders page filtered by vendortransaction
    
    <OrderList
      canChangeStatus={false}
      vendorTransactionId={vendorTransactionIdOrdersFilter}
      returnToOriginalComponent={() =>
        setDisplayVendorTransactionsByOrder(false)
      }
    />
  ) : (
    // otherwise, show the main VendorTransactions page

    <BasePage snackBarErrorClients={[httpClient]}>

      <GenericPopup
        open={openOrderBasicDetailsPopup}
        setOpen={setOpenOrderBasicDetailsPopup}
        data={orderBasicDetailsData || {}}
      />

      <PageUpper>
        <PageTitle title="Financial Transactions"/>
      </PageUpper>

      <div
          style={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
            justifyContent: "flex-end",
          }}>
            {vendorTransactionStatuses.map((object, i) => (
              <CustomButton
                key={i}
                text={object.name}
                onClick={() => {
                  setCount(0);
                  setItems([]);
                  setStatus(i);
                  dataGridApiRef.current.setPage(0);
                }}
                isSecondary={status === i ? false : true}
              />
            ))}
          </div>
      
        <SearchBar
          fetchDataById={getSearchBarItemById}
          searchBarResults={searchBarResults}
          setSearchBarResults={setSearchBarResults}
        />

        <TableList
          rows={searchBarResults.length > 0 ? searchBarResults : items}
          isLoading={httpClient.isLoading}
          getItems={(p) => getVendorTransactions(false)}
          columns={columns}
          dataGridApiRef={dataGridApiRef}
          rowsCount={count}
          showRowNumbers
          rowNumbersDescending
        />
    </BasePage>
  )
};

export default FinancialTransactionList;
