import React, { useEffect, useState } from "react";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { useHttpClient } from "../../Hooks/HttpHook";
import SnackbarErrorClient from "../../Components/SnackbarError/SnackbarErrorClient";
import { VendorStatus } from "../../Data/Enums";
import ReviewOrEditVendor from "../../Components/ReviewOrEditVendor/ReviewOrEditVendor";
import TableList from "../../Components/Table/Table";
import { FaEdit, FaExternalLinkAlt, FaTrash } from "react-icons/fa";
import ImagePopup from "../../Components/ImagePopup/ImagePopup";
import formatDate from "../../Utils/formatDate";
import SearchBar from "../../Components/SearchBar/SearchBar";
import { IVendor } from "../../Models/IVendor";
import ProductList from "../ProductList/ProductList";
import GenericPopup from "../../Components/GenericPopup/GenericPopup";
import DeletePopup from "../../Components/DeletePopup/DeletePopup";
import { useDeleteHandler } from "../../Hooks/useDeleteHandler";
import usePaginationUtil from "../../Hooks/usePaginationUtil";
import useSearchBar from "../../Hooks/useSearchBar";
import { IS_NODE_BACKEND, PAGE_SIZE } from "../../Shared/globalVar";
import { usePaginationManager } from "../../Hooks/usePaginationManager";
import VendorService from "../../Services/VendorService";
import { BasePage } from "../../Layout/BasePage";
import { PageUpper } from "../../Layout/PageUpper";
import { PageTitle } from "../../Layout/PageTitle";

interface VendorListProps {}

const VendorList: React.FunctionComponent<VendorListProps> = () => {
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [openReview, setOpenReview] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageModalSrc, setImageModalSrc] = useState("");
  const [selectedVendorProductsId, setSelectedVendorProductsId] = useState<
    string | null
  >(null);
  const [editingVendor, setEditingVendor] = useState(false);
  const [openVendorLocationModal, setOpenVendorLocationModal] = useState(false);
  const [vendorLocationData, setVendorLocationData] = useState({});
  const [displayProductsByVendor, setDisplayProductsByVendor] = useState(false);

  const [openVendorUserPopup, setOpenVendorUserPopup] = useState(false);
  const [vendorUserData, setVendorUserData] = useState({});

  const { openDeletePopup, setOpenDeletePopup, handleDeleteClick, deleteItem, deleteClient } = useDeleteHandler({
    requestUrl: "/owner/vendor/delete",
    requestMethod: "post",
    onSuccess: async () => {
      await getVendors();
    },
  });

  const {
    items,
    setItems,
    dataGridApiRef,
    count,
    setCount,
    status,
    setStatus,
    httpClient
  } = usePaginationManager();

  const {
    searchBarResults,
    setSearchBarResults,
    getSearchBarItemById,
    getSearchBarItemByName
  } = useSearchBar({
    getByIdRequestUrl: "/owner/vendor/get",
    getByIdRequestMethod: "post",
    getByNameRequestUrl: "/owner/vendor/get",
    getByNameRequestMethod: "post",
    responseField: "content"
  });

  let vendorStatuses = [
    { name: 
      VendorStatus.All, 
      vendors: [], 
      needsLoading: true, 
      count: 0 
    },
    { name: 
      VendorStatus.Pending, 
      vendors: [], 
      needsLoading: true, 
      count: 0 
    },
    {
      name: VendorStatus.Accepted,
      vendors: [],
      needsLoading: true,
      count: 0,
    },
    {
      name: VendorStatus.Rejected,
      vendors: [],
      needsLoading: true,
      count: 0,
    },
  ];
  const columns: GridColDef[] = [
    {
      field: "logo",
      headerName: "Logo",
      width: 90,
      renderCell: (params) =>
        params.row.logo && (
          <img
            width={120}
            height={90}
            src={params.row.logo}
            alt="logo"
            onClick={() => {
              console.log("opening image popup");
              setOpenImageModal(true);
              setImageModalSrc(params.row.logo);
            }}
          />
        ),
    },
    { 
      field: "id", 
      headerName: "ID", 
      width: 315,
      editable: true
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      editable: true,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 150,
      editable: true,
    },
    {
      field: "products",
      headerName: "Products",
      width: 80,
      editable: true,
      renderCell: (params) => (
        <FaExternalLinkAlt
          onClick={() => {
            setSelectedVendorProductsId(params.row.id);
            console.log(`selected vendor products: ` + params.row.id);
            setDisplayProductsByVendor(true);
          }}
        />
      ),
    },
    {
      field: "review",
      headerName: "Review",
      width: 60,
      editable: true,
      renderCell: (params) => (
        <FaExternalLinkAlt
          onClick={() => {
            handleEvent(params);
          }}
        />
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 50,
      editable: true,
      renderCell: (params) => (
        <FaExternalLinkAlt
          onClick={() => {
            setEditingVendor(true);
            setOpenReview(true);
            setSelectedVendor(params.row);
          }}
        />
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 60,
      renderCell: (params) => (
        <FaTrash
          onClick={() => {
            handleDeleteClick(params.row.id);
          }}
        />
      ),
    },
    {
      field: "location",
      headerName: "Location",
      width: 70,
      renderCell: (params) => (
        <FaExternalLinkAlt
        onClick={async () => {
          setVendorLocationData({});
          console.log("getting location. Row data: ")
          console.log(params.row);
          await getVendorLocation(params.row.id);
          setOpenVendorLocationModal(true);
        }}
        
        />
      ),
    },
    {
      field: "vendorUser",
      headerName: "Vendor User",
      width: 70,
      renderCell: (params) => (
        <FaExternalLinkAlt
        onClick={async () => {
          setVendorUserData({});
          console.log(params.row);
          await getVendorUserInformation(params.row.id);
          setOpenVendorUserPopup(true);
        }}
        
        />
      ),
    },
    {
      field: "otherPhoneNumber",
      headerName: "Other Phone Number",
      width: 130,
      editable: true,
    },
    {
      field: "socialMediaLink",
      headerName: "Social Media Link",
      width: 200,
      editable: true,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 170,
      editable: true,
      valueGetter: (params) => formatDate(params.value),
    },
  ];


  const getVendors = async () => {
    
    setSelectedVendor(null);

    await VendorService.getItems({
      status: status,
      statuses: vendorStatuses,
      getCount: true,
      setCount: setCount,
      items: items,
      setItems: setItems,
      httpClient: httpClient,
      dataGridApiRef: dataGridApiRef,
      requestUrl: "/owner/vendor/get",
      requestMethod: "post",
      responseField: "content"
    });
    
  };

  const getVendorLocation = async (vendorId: string) => {

    await VendorService.getVendorLocation({
      httpClient: httpClient,
      setVendorLocationData: setVendorLocationData,
      vendorId: vendorId,
    });
  };

  const getVendorUserInformation = async(vendorUserId: string) => {
    await VendorService.getVendorUserInformation({
      httpClient: httpClient,
      setVendorUserData: setVendorUserData,
      vendorUserId: vendorUserId
    })
  }

  useEffect(() => {
    getVendors();
  }, [status]);

  const handleEvent = (data: any) => {
    console.log(data);
    console.log(data.row);

    setTimeout(() => {
      setOpenReview(true);
      setSelectedVendor(data.row);
    }, 50);
  };

  return displayProductsByVendor ? (
    // show the products page filtered by vendor

    <ProductList
      vendorId={selectedVendorProductsId}
      returnToOriginalComponent={() => {
        setDisplayProductsByVendor(false);
        setSelectedVendorProductsId(null);
      }}
    />
    
  ) : (
    // otherwise, show the main Vendors page

    <BasePage snackBarErrorClients={[httpClient]}>

      <ReviewOrEditVendor
        getVendors={getVendors}
        open={openReview}
        setOpen={setOpenReview}
        vendor={selectedVendor}
        editing={editingVendor}
        setEditing={setEditingVendor}
      />

      <DeletePopup
        open={openDeletePopup}
        setOpen={setOpenDeletePopup}
        deleteFunction={deleteItem}
        deleteHttpClient={deleteClient}
      />

      <ImagePopup
        open={openImageModal}
        setOpen={setOpenImageModal}
        imageSrc={imageModalSrc}
      />

      <GenericPopup
        data={vendorUserData}
        open={openVendorUserPopup}
        setOpen={setOpenVendorUserPopup}
      />

      <GenericPopup
        data={vendorLocationData}
        open={openVendorLocationModal}
        setOpen={setOpenVendorLocationModal}
      />

      <PageUpper>
        <PageTitle title="Vendors"/>
        <div className="pageUpperCTAs">
          {vendorStatuses.map((object, i) => (
            <CustomButton
              key={i}
              text={object.name}
              onClick={() => {
                setCount(0);
                setItems([]);
                setStatus(i);
              }}
              isSecondary={status === i ? false : true}
            />
          ))}
        </div>
      </PageUpper>

      <SearchBar
        fetchDataById={getSearchBarItemById}
        fetchDataByName={getSearchBarItemByName}
        searchBarResults={searchBarResults}
        setSearchBarResults={setSearchBarResults}
      />
      <TableList
        // onRowClick={handleEvent}
        rows={searchBarResults.length > 0 ? searchBarResults : items}
        isLoading={httpClient.isLoading}
        columns={columns}
        rowsCount={count}
        getItems={() => getVendors()}
        dataGridApiRef={dataGridApiRef}
        showRowNumbers
        rowNumbersDescending
      />
    </BasePage>
  );
};

export default VendorList;
