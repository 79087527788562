import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { useHttpClient } from "../../Hooks/HttpHook";
import SnackbarErrorClient from "../../Components/SnackbarError/SnackbarErrorClient";
import TableList from "../../Components/Table/Table";
import AddUpdateTouristSection from "../../Components/AddUpdateTouristSection/AddUpdateTouristSection";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { ITouristSection } from "../../Models/ITouristSection";
import { FaEdit, FaTrash } from "react-icons/fa";
import { MenuItem, Select } from "@mui/material";
import ImagePopup from "../../Components/ImagePopup/ImagePopup";
import DeletePopup from "../../Components/DeletePopup/DeletePopup";
import { useDeleteHandler } from "../../Hooks/useDeleteHandler";
import { BasePage } from "../../Layout/BasePage";
import { PageUpper } from "../../Layout/PageUpper";
import { PageTitle } from "../../Layout/PageTitle";
import { useBasicDetails } from "../../Hooks/useBasicDetails";
import GenericPopup from "../../Components/GenericPopup/GenericPopup";

interface TouristSectionListProps {}

const TouristSectionList: React.FunctionComponent<
  TouristSectionListProps
> = () => {
  const [TouristSections, setTouristSections] = useState<any[]>([]);
  const [selectedTouristSection, setSelectedTouristSection] = useState<ITouristSection | null>();
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageModalSrc, setImageModalSrc] = useState("");
  const getTouristSectionsClient = useHttpClient();
  const [openAddUpdateModal, setOpenAddUpdateModal] = useState(false);
  const [count, setCount] = useState(0);
  
  const { openDeletePopup, setOpenDeletePopup, handleDeleteClick, deleteItem, deleteClient } = useDeleteHandler({
    requestUrl: "/owner/homesection/touristic/delete",
    requestMethod: "post",
    onSuccess: async () => {
      setTouristSections([]);
      await getTouristSections();
    },
  });

  const {
    fetchBasicDetails: fetchCategoryBasicDetails,
    getBasicDetailsHttpClient: getCategoryBasicDetailsClient,
    basicDetailsData: categoryBasicDetailsData,
    openBasicDetailsPopup: openCategoryBasicDetailsPopup, 
    setOpenBasicDetailsPopup: setOpenCategoryBasicDetailsPopup
    } =
    useBasicDetails({
      baseUrl: "/owner/category/basic-details",
      method: "get"
  });

  useEffect(() => {
    if (openAddUpdateModal === false) {
      setSelectedTouristSection(null);
    }
  }, [openAddUpdateModal, setOpenAddUpdateModal]);
  

  useEffect(() => {
    getTouristSections();
  }, []);


  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 250 },
    {
      field: "Image",
      headerName: "Image",
      width: 90,
      renderCell: (params) =>
        params.row.image && (
          <img
            width={120}
            height={90}
            src={params.row.image}
            alt="logo"
            onClick={() => {
              console.log("opening image popup");
              setOpenImageModal(true);
              setImageModalSrc(params.row.image);
            }}
          />
        ),
    },
    {
      field: "imageAr",
      headerName: "Image AR",
      width: 90,
      renderCell: (params) =>
        params.row.imageAr && (
          <img
            width={120}
            height={90}
            src={params.row.imageAr}
            alt="logo"
            onClick={() => {
              console.log("opening image popup arabic");
              setOpenImageModal(true);
              setImageModalSrc(params.row.imageAr);
            }}
          />
        ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 150,
      editable: true,
    },
    {
      field: "nameAr",
      headerName: "Name AR",
      width: 100,
      editable: true,
    },
    {
      field: "category",
      headerName: "Category ID",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={async () => {
            await fetchCategoryBasicDetails(params.row.category);
            setOpenCategoryBasicDetailsPopup(true);
          }}
        >
          {params.row.category}
        </div>
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 50,
      renderCell: (params) => (
        <FaEdit
          onClick={() => {
            setSelectedTouristSection(params.row);

            setOpenAddUpdateModal(true);
          }}
        />
      ),
    },
    {
      field: "isActive",
      headerName: "Active Status",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <Select
          value={params.row.isActive}
          onChange={async (e) => {
            console.log(
              `Updating active status. New status: ${e.target.value}`
            );
            await updateActiveStatus(params.row.id, e.target.value === "true")
            await getTouristSections();
          }}
        >
          <MenuItem value="true">True</MenuItem>
          <MenuItem value="false">False</MenuItem>
        </Select>
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 60,
      renderCell: (params) => (
        <FaTrash
          onClick={() => {
            handleDeleteClick(params.row.id);
          }}
        />
      ),
    },
  ];

  const getTouristSections = async () => {
    try {
      const url = "/owner/homesection/touristic";
      
      const response = await getTouristSectionsClient.sendRequest({
        url: url,
        method: "get",
        body: null,
        headers: {
          headers: {
            "Content-Type": "application/json",
            //withCredentials: true
          },
        },
      });
      console.log(response);

        if (response && response.content) {

          setTouristSections(response.content);
          setCount(response.totalElements);
  
        }

    } catch (e) {
      console.log(e);
      console.log(getTouristSectionsClient.error);
    }
  };

  const updateActiveStatus = async (id: any, isActive: any) => {

    try {
      const url = "/owner/homesection/touristic/isactive";
      
      const response = await getTouristSectionsClient.sendRequest({
        url: url,
        method: "put",
        body: { id, isActive, sectionType: "touristic" },
        headers: {
          headers: {
            "Content-Type": "application/json",
            //withCredentials: true
          },
        },
      });
      console.log(response);

    } catch (e) {
      console.log(e);
      console.log(getTouristSectionsClient.error);
    }
  };



  return (

    <BasePage snackBarErrorClients={[getTouristSectionsClient]}>

      <ImagePopup
        open={openImageModal}
        setOpen={setOpenImageModal}
        imageSrc={imageModalSrc}
      />

      <GenericPopup
        open={openCategoryBasicDetailsPopup}
        setOpen={setOpenCategoryBasicDetailsPopup}
        data={categoryBasicDetailsData || {}}
      />

      <DeletePopup
        open={openDeletePopup}
        setOpen={setOpenDeletePopup}
        deleteFunction={deleteItem}
        deleteHttpClient={deleteClient}
      />

      <AddUpdateTouristSection
        open={openAddUpdateModal}
        touristSection={selectedTouristSection}
        setOpen={setOpenAddUpdateModal}
        getTouristSections={getTouristSections}
      />

      <PageUpper>
        <PageTitle title="Tourist Sections"/>
        <CustomButton
          text="Add Tourist Section"
          onClick={() => {
            setOpenAddUpdateModal(true);
          }}
          isSecondary={true}
        />
      </PageUpper>

      <TableList
        rows={TouristSections}
        isLoading={getTouristSectionsClient.isLoading}
        columns={columns}
        rowsCount={count}
      />
    </BasePage>
  );
};

export default TouristSectionList;
