import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../Hooks/useAuth";

const RequireAuth = ({ allowedRoles }: any) => {
  const { auth }: any = useAuth();
  const location = useLocation();

  console.log("RequireAuth - auth: ");
  console.log(auth);

  console.log(`RequireAuth - allowedRoles`);
  console.log(allowedRoles);

  console.log(`RequireAuth - auth.permissions`);
  console.log(auth.permissions);
  
  return (
    // <Outlet />
    // auth?.username ? (
    //   <Outlet />
    // ) : (
    //   <Navigate to="/login" state={{ from: location }} replace />
    // )

    // console.log

        auth?.permissions?.find((role : any) => allowedRoles?.includes(role))
            ? <Outlet />
            : auth?.username
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
