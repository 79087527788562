import React, { useEffect, useMemo, useState } from "react";
import { useHttpClient } from "../../Hooks/HttpHook";
import SnackbarErrorClient from "../SnackbarError/SnackbarErrorClient";
import {
  Alert,
  Box,
  Button,
  Card,
  CardMedia,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import FormInputText from "../FormInputText/FormInputText";
import CustomButton from "../CustomButton/CustomButton";
import PageLoader from "../PageLoader";
import "../ReviewOrEditProduct/ReviewProduct.scss";
import { useForm } from "react-hook-form";
import { ICategory } from "../../Models/ICategory";
import { Cropper, getCroppedImg } from "react-cropper-custom";
import SingleImageUpload from "../SingleImageUpload/SingleImageUpload";
import imageCompression from "browser-image-compression";
import { IVendor } from "../../Models/IVendor";
import { IHomeSection } from "../../Models/IHomeSection";
import { IDiscount } from "../../Models/IDiscount";
import { HomeSectionType } from "../../Data/Enums";
import "./AddUpdateHomeSection.scss";
import AutofillStatusSelector from "./AutofillStatusSelector";
import SectionTypeSelector from "./SectionTypeSelector";
import CustomFormInputList from "../CustomFormInputList/CustomFormInputList";
import IconUploadSelector from "../IconUploadSelector.tsx/IconUploadSelector";
import SubtypeSelector from "./SubTypeSelector";
import { handleAddItem, handleRemoveItem } from "../CustomFormInputList/CustomFormInputListUtils";
import { IS_NODE_BACKEND } from "../../Shared/globalVar";
import useSingleImageUploadManager from "../../Hooks/useSingleImageUploadManager";

type Area = {
  width: number;
  height: number;
  x: number;
  y: number;
};

interface AddUpdateHomeSectionProps {
  homeSection?: IHomeSection | null;

  open: any;
  setOpen: any;
  getHomeSections: any;
}

const AddUpdateHomeSection: React.FunctionComponent<
  AddUpdateHomeSectionProps
> = (props) => {
  const [error, setError] = useState("");
  const handleClose = () => props.setOpen(false);

  const submitHomeSectionClient = useHttpClient();

  const [vendors, setVendors] = useState<string[]>([]);
  const [newVendor, setNewVendor] = useState<string>("");

  const [categories, setCategories] = useState<string[]>([]);
  const [newCategory, setNewCategory] = useState("");

  const [products, setProducts] = useState<string[]>([]);
  const [newProduct, setNewProduct] = useState<string>("");

  const [discounts, setDiscounts] = useState<string[]>([]);
  const [newDiscount, setNewDiscount] = useState<string>("");

  const [homeSectionType, setHomeSectionType] = useState("products");
  const [autoFillStatus, setAutoFillStatus] = useState<"true" | "false">(
    "true"
  );
  // const [name, setName] = useState<string>("");
  // const [nameAr, setNameAr] = useState<string>("");
  const [subType, setSubType] = useState<string>("");

  const {
    image,
    imageSrc,
    initImageSrc,
    zoom,
    uploadNewImage,
    setImage,
    setImageSrc,
    setInitImageSrc,
    setZoom,
    toggleUploadNewImage,
    handleImageUpload,
    handleIconUploadChange
  } = useSingleImageUploadManager();

  const {
    image: imageAr,
    imageSrc: imageSrcAr,
    initImageSrc: initImageSrcAr,
    zoom: zoomAr,
    uploadNewImage: uploadNewImageAr,
    setImage: setImageAr,
    setImageSrc: setImageSrcAr,
    setInitImageSrc: setInitImageSrcAr,
    setZoom: setZoomAr,
    toggleUploadNewImage: toggleUploadNewImageAr,
    handleImageUpload: handleImageUploadAr,
    handleIconUploadChange: handleIconUploadChangeAr
  } = useSingleImageUploadManager();

  let defValues = {};

  const { register, reset, handleSubmit, control, getValues } = useForm({
    defaultValues: useMemo(() => {
      return defValues;
    }, [props]),
  });

  
  useEffect(() => {
    setError("");
    
    if (props.homeSection) {
      // Set initial values for form fields
      const initialValues = {
        name: props.homeSection.name || "",
        nameAr: props.homeSection.nameAr || "",
      };
      reset(initialValues);

      // Set other state values
      setVendors(props.homeSection.vendors || []);
      setCategories(props.homeSection.categories || []);
      setProducts(props.homeSection.products || []);
      setDiscounts(props.homeSection.discounts || []);
      setHomeSectionType(props.homeSection.type || "products");
      setAutoFillStatus(props.homeSection.autoFill ? "true" : "false");
      setSubType(props.homeSection.subType || "");

      // Set initial image states
      if (props.homeSection.icon) {
        setInitImageSrc(props.homeSection.icon);
      }
      if (props.homeSection.iconAr) {
        setInitImageSrcAr(props.homeSection.iconAr);
      }
    } else {
      // Reset form for new section
      reset({
        name: "",
        nameAr: "",
      });
      setVendors([]);
      setCategories([]);
      setProducts([]);
      setDiscounts([]);
      setHomeSectionType("products");
      setAutoFillStatus("true");
      setSubType("");
    }
  }, [props.homeSection, reset]);


  const handleSectionTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setHomeSectionType(event.target.value as HomeSectionType);
    console.log("homesectiontype: ");
    console.log(homeSectionType);
  };

  const handleAutoFillStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAutoFillStatus(event.target.value as "true" | "false");
  };

  const onSubmit = async () => {
    const values = control._formValues;
    try {
      let formData = new FormData();
      submitHomeSectionClient.setLoading(true);

      // Handle English icon
      if (uploadNewImage && imageSrc) {
        const compressedFile = await imageCompression(imageSrc, {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 500,
          useWebWorker: true,
        });
        formData.append("icon", compressedFile);
      } else if (props.homeSection?.icon) {
        // If editing and not uploading new image, pass the existing URL
        formData.append("existingIcon", props.homeSection.icon);
      }

      // Handle Arabic icon
      if (uploadNewImageAr && imageSrcAr) {
        const compressedFileAr = await imageCompression(imageSrcAr, {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 500,
          useWebWorker: true,
        });
        formData.append("iconAr", compressedFileAr);
      } else if (props.homeSection?.iconAr) {
        // If editing and not uploading new image, pass the existing URL
        formData.append("existingIconAr", props.homeSection.iconAr);
      }

      // Add other form data
      formData.append("name", values.name);
      formData.append("nameAr", values.nameAr);
      formData.append("subType", subType);
      formData.append("type", homeSectionType);
      formData.append("autoFill", autoFillStatus);
      formData.append("vendors", JSON.stringify(vendors));
      formData.append("products", JSON.stringify(products));
      formData.append("discounts", JSON.stringify(discounts));
      formData.append("categories", JSON.stringify(categories));

      if (props.homeSection) {
        formData.append("id", props.homeSection.id || "");
        
        await submitHomeSectionClient.sendRequest({
          url: "/owner/homesection",
          method: "put",
          body: formData,
        });
      } else {
        await submitHomeSectionClient.sendRequest({
          url: "/owner/homesection",
          method: "post",
          body: formData,
        });
      }

      props.setOpen(false);
      props.getHomeSections();
    } catch (e) {
      console.log(e);
      setError("Failed to save home section. Please try again.");
    } finally {
      submitHomeSectionClient.setLoading(false);
    }
  };

  return (
    <div>
      <SnackbarErrorClient client={submitHomeSectionClient} />
      <PageLoader loading={submitHomeSectionClient.isLoading} />

      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="reviewProductBox">
          <p className="pageTitle">
            {props.homeSection ? "Edit" : "Create"}{" "}
            {props.homeSection?.name ?? ""} Home Section
          </p>
          <h3>Section Type</h3>

          {/* Choose section type */}

          <SectionTypeSelector
            value={homeSectionType}
            onChange={handleSectionTypeChange}
          />

          {/* Choose autofill status */}

          <h3>Autofill Status</h3>

          <AutofillStatusSelector
            value={autoFillStatus}
            onChange={handleAutoFillStatusChange}
          />

          <form className="reviewProduct" onSubmit={handleSubmit(onSubmit)}>
            <FormInputText
              rules={{
                required: true,
              }}
              value={props.homeSection?.name}
              control={control}
              name="name"
              label={"name"}
            />
            <FormInputText
              rules={{
                required: true,
              }}
              control={control}
              value={props.homeSection?.nameAr}
              name="nameAr"
              label={"Name Arabic"}
            />

            {/* Subtype selector */}

            <SubtypeSelector value={subType} onChange={setSubType} />

            {/* Add vendors to home section */}

            {((autoFillStatus === "false" && homeSectionType === "vendors") ||
              (autoFillStatus === "true" && homeSectionType !== "vendors")) && (
              <CustomFormInputList
                title="Vendors"
                items={vendors}
                newItem={newVendor}
                onNewItemChange={setNewVendor}
                onAddItem={()=>{ handleAddItem(newVendor, setNewVendor, vendors, setVendors) }}
                onRemoveItem={(index) => handleRemoveItem(index, vendors, setVendors)} 
              />
            )}

            {/* Add categories to home section */}

            {autoFillStatus === "true" && (
              <CustomFormInputList
                title="Categories"
                items={categories}
                newItem={newCategory}
                onNewItemChange={setNewCategory}
                onAddItem={()=>{handleAddItem(newCategory, setNewCategory, categories, setCategories)}}
                onRemoveItem={(index) => handleRemoveItem(index, categories, setCategories)} 
              />
            )}

            {/* Add products to home section */}
            {((autoFillStatus === "false" && homeSectionType === "products") ||
              (autoFillStatus === "true" &&
                homeSectionType !== "products")) && (
              <CustomFormInputList
                title="Products"
                items={products}
                newItem={newProduct}
                onNewItemChange={setNewProduct}
                onAddItem={()=>{handleAddItem(newProduct, setNewProduct, products, setProducts)}}
                onRemoveItem={(index) => handleRemoveItem(index, products, setProducts)} 
              />
            )}

            {/* Add discounts to home section */}
            {autoFillStatus === "true" && (
              <CustomFormInputList
                title="Discounts"
                items={discounts}
                newItem={newDiscount}
                onNewItemChange={setNewDiscount}
                onAddItem={()=>{handleAddItem(newDiscount, setNewDiscount, discounts, setDiscounts)}}
                onRemoveItem={(index) => handleRemoveItem(index, discounts, setDiscounts)} 
              />
            )}

            {/* Choose whether to upload new image or use existing image */}

            <IconUploadSelector
              title="Section Icon (EN)"
              uploadNewImage={uploadNewImage}
              handleIconUploadChange={handleIconUploadChange}
            />

            {/* Show existing image */}

            {/* Show message if image url exists */}
            {!uploadNewImage &&
              (props.homeSection?.icon ? (
                <p>Existing image found.</p>
              ) : (
                <p>No existing image found</p>
              ))}

            {!uploadNewImage && (
              <img width={90} height={90} src={props.homeSection?.icon} />
            )}

            {/* Upload new image */}
            {uploadNewImage && (
              <SingleImageUpload
                title={""}
                img={initImageSrc}
                setImg={setImageSrc}
                aspectRatio={16/9}
              />
            )}

            {/* Arabic image upload */}
            <IconUploadSelector
              title="Section Icon (AR)"
              uploadNewImage={uploadNewImageAr}
              handleIconUploadChange={handleIconUploadChangeAr}
            />

            {!uploadNewImageAr && (
              <>
                {props.homeSection?.iconAr ? (
                  <p>Existing Arabic image found.</p>
                ) : (
                  <p>No existing Arabic image found</p>
                )}
                <img width={90} height={90} src={props.homeSection?.iconAr} />
              </>
            )}

            {uploadNewImageAr && (
              <SingleImageUpload
                title={""}
                img={initImageSrcAr}
                setImg={setImageSrcAr}
                aspectRatio={16/9}
              />
            )}

            {error && <Alert severity="error">{error}</Alert>}

            {/* Submit */}
            <CustomButton
              text={props.homeSection ? "Edit" : "Create"}
              onClick={handleSubmit(onSubmit)}
            />
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default AddUpdateHomeSection;
