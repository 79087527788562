import React, { useEffect, useMemo, useState } from "react";
import { useHttpClient } from "../../Hooks/HttpHook";
import SnackbarErrorClient from "../SnackbarError/SnackbarErrorClient";
import { Alert, Box, Modal } from "@mui/material";
import FormInputText from "../FormInputText/FormInputText";
import CustomButton from "../CustomButton/CustomButton";
import PageLoader from "../PageLoader";
import "../ReviewOrEditProduct/ReviewProduct.scss";
import { useForm } from "react-hook-form";
import { ICategory } from "../../Models/ICategory";
import { Cropper, getCroppedImg } from "react-cropper-custom";
import SingleImageUpload from "../SingleImageUpload/SingleImageUpload";
import imageCompression from "browser-image-compression";

type Area = {
  width: number;
  height: number;
  x: number;
  y: number;
};

interface AddUpdateCategoryProps {
  parentId?: any | null;
  parentName?: any | null;
  isFashion?: boolean | null;

  sellRateAmount?: number | null;
  sellRatePercentage?: number | null;
  parentGender?: any | null;
  parentForKids?: any | null;
  parentSize?: any | null;

  category?: ICategory | null;

  open: any;
  setOpen: any;
  getCategories: any;
}

const AddUpdateCategory: React.FunctionComponent<AddUpdateCategoryProps> = (
  props
) => {
  const [error, setError] = useState("");
  const handleClose = () => props.setOpen(false);
  const [image, setImage] = useState({ preview: "", data: "" });
  const [imageSrc, setImageSrc] = useState<any>(null);
  const [initImageSrc, setInitImageSrc] = useState<any>(null);
  const [imageSrcMale, setImageSrcMale] = useState<any>(null);
  const [initImageSrcMale, setInitImageSrcMale] = useState<any>(null);

  const [imageSrcFemale, setImageSrcFemale] = useState<any>(null);
  const [initImageSrcFemale, setInitImageSrcFemale] = useState<any>(null);

  const [imageSrcKids, setImageSrcKids] = useState<any>(null);
  const [initImageSrcKids, setInitImageSrcKids] = useState<any>(null);

  // const [img, setImg] = useState("./2521.jpg");
  const [zoom, setZoom] = useState(1);
  const [img, setImg] = useState("./2521.jpg");

  const submitCategoryClient = useHttpClient();

  const getDefaultValues = () => {
    if (props.category) {
      return {
        name: props.category.name || '',
        nameAr: props.category.nameAr || '',
        sellRateAmount: props.category.sellRateAmount || '',
        sellRatePercentage: props.category.sellRatePercentage || '',
        gender: props.category.gender ? {
          label: props.category.gender.charAt(0).toUpperCase() + props.category.gender.slice(1),
          value: props.category.gender
        } : null,
        forKids: props.category.forKids !== undefined ? {
          label: props.category.forKids ? "True" : "False",
          value: String(props.category.forKids)
        } : null,
        sizeType: props.category.size ? {
          label: props.category.size.charAt(0).toUpperCase() + props.category.size.slice(1),
          value: props.category.size
        } : null
      };
    }
    return {};
  };

  useEffect(() => {
    setError("");

    // if (props.product) {
    //   console.log(props.product);
    if (props.category?.logo) {
      setInitImageSrc(props.category.logo);
    } else {
      setInitImageSrc(null);
    }
    if (props.category?.images) {
      if (props.category?.images?.male) {
        setInitImageSrcMale(props.category?.images?.male);
      } else {
        setInitImageSrcMale(null);
      }
      if (props.category?.images?.female) {
        setInitImageSrcFemale(props.category?.images?.female);
      } else {
        setInitImageSrcFemale(null);
      }
      if (props.category?.images?.kids) {
        setInitImageSrcKids(props.category?.images?.kids);
      } else {
        setInitImageSrcKids(null);
      }
    } else {
      setInitImageSrcMale(null);

      setInitImageSrcFemale(null);

      setInitImageSrcKids(null);
    }
    setImageSrc(null);
    setImageSrcMale(null);
    setImageSrcFemale(null);
    setImageSrcKids(null);

    reset(getDefaultValues());
    // } else {
    // }
    console.log("hoooooooo");
  }, [props]);

  const { register, reset, handleSubmit, control, getValues } = useForm({
    defaultValues: useMemo(() => getDefaultValues(), [props.category])
  });

  const onSubmit = async () => {
    const values = control._formValues;

    try {
      let formData = new FormData();
      submitCategoryClient.setLoading(true);
      let imagesTypes = [];

      console.log(imageSrc);
      console.log(imageSrcMale);
      console.log(imageSrcFemale);
      console.log(imageSrcKids);

      if (imageSrc != null) {
        console.log("IMMMM SOURCE")
        const compressedFile = await imageCompression(imageSrc, {
          maxSizeMB: 0.3,
          maxWidthOrHeight: 390,
          useWebWorker: true,
        });

        console.log("IMMMM SOURCE")


        formData.append("image_main", compressedFile);
        imagesTypes.push("main")

      }

      if (imageSrcMale != null) {
        const compressedFileM = await imageCompression(imageSrcMale, {
          maxSizeMB: 0.3,
          maxWidthOrHeight: 390,
          useWebWorker: true,
        });

        formData.append("image_male", compressedFileM);
        imagesTypes.push("male")

      }

      if (imageSrcFemale != null) {
        const compressedFileF = await imageCompression(imageSrcFemale, {
          maxSizeMB: 0.3,
          maxWidthOrHeight: 390,
          useWebWorker: true,
        });

        formData.append("image_female", compressedFileF);
        imagesTypes.push("female")

      }

      if (imageSrcKids != null) {
        const compressedFilek = await imageCompression(imageSrcKids, {
          maxSizeMB: 0.3,
          maxWidthOrHeight: 390,
          useWebWorker: true,
        });

        formData.append("image_kids", compressedFilek);
        imagesTypes.push("kids")

      }
      submitCategoryClient.setLoading(false);
      formData.append("imagesTypes", (JSON.stringify(imagesTypes)));

      formData.append("name", values.name);
      formData.append("nameAr", values.nameAr);
      
      if (!props.parentId) {
        formData.append("sellRateAmount", values.sellRateAmount || "0");
        formData.append("sellRatePercentage", values.sellRatePercentage || "0");
      }

      if (values.gender?.value === "male" || values.gender?.value === "female") {
        formData.append("gender", values.gender.value);
      }
      
      if (values.sizeType?.value && values.sizeType.value !== "none") {
        formData.append("size", values.sizeType.value);
      }
      
      if (values.forKids?.value) {
        formData.append("forKids", values.forKids.value);
      }

      if (props.parentId) {
        console.log('Parent ID being sent:', props.parentId);

        formData.append("parent", props.parentId);
      }


      console.log(`AddUpdateCategory - FormData: `)
      formData.forEach((key, value) => {
        console.log(`${key}: ${value}`)
      })

      if (props.category) {
        
        formData.append("id", props.category.id ?? "");


        const response = await submitCategoryClient.sendRequest({
          url: "/owner/category",
          method: "put",
          body: formData,
          headers: {
            headers: { "Content-Type": "multipart/form-data" },
            // withCredentials: true
          },
        });
        console.log(response);
      } else {
        const response = await submitCategoryClient.sendRequest({
          url: "/owner/category",
          method: "post",
          body: formData,
          headers: {
            headers: { "Content-Type": "multipart/form-data" },
            // withCredentials: true
          },
        });
        console.log(response);
      }

      

      props.setOpen(false);
      props.getCategories();
    } catch (e) {
      console.log(e);
      submitCategoryClient.setLoading(false);
    }
    console.log(values);
  };



  return (
    <div>
      <SnackbarErrorClient client={submitCategoryClient} />
      <PageLoader loading={submitCategoryClient.isLoading} />

      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="reviewProductBox">
          <p className="pageTitle">
            {props.category ? "Edit" : "Create"} {props.parentName ?? ""}{" "}
            Category
          </p>

          <form className="reviewProduct" onSubmit={handleSubmit(onSubmit)}>
            <FormInputText
              rules={{
                required: true,
              }}
              value={props.category?.name}
              control={control}
              name="name"
              label={"name"}
            />
            <FormInputText
              rules={{
                required: true,
              }}
              control={control}
              value={props.category?.nameAr}
              name="nameAr"
              label={"Name Arabic"}
            />
            {!props.parentId && (
              <FormInputText
                rules={{
                  required: true,
                }}
                control={control}
                type="number"
                value={props.category?.sellRate?.sellRateAmount}
                name="sellRateAmount"
                label={"Sell Rate Amount"}
              />
            )}

            {!props.parentId && (
              <FormInputText
                rules={{
                  required: true,
                }}
                control={control}
                value={props.category?.sellRate?.sellRatePercentage}
                type="number"
                name="sellRatePercentage"
                label={"Sell Rate Percentage"}
              />
            )}
            {/* <input type="file" name="file" title="Main I" onChange={handleFileChange}></input> */}
            <SingleImageUpload
              title={"Main Image"}
              img={initImageSrc}
              setImg={setImageSrc}
            />

            {props.isFashion == true && (
              <>
                <SingleImageUpload
                  img={initImageSrcMale}
                  title={"Male Image"}
                  setImg={setImageSrcMale}
                />
                <SingleImageUpload
                  img={initImageSrcFemale}
                  title={"Female Image"}
                  setImg={setImageSrcFemale}
                />
                <SingleImageUpload
                  img={initImageSrcKids}
                  title={"Kids Image"}
                  setImg={setImageSrcKids}
                />
              </>
            )}

            {!props.parentGender && (
              <FormInputText
                rules={{}}
                inputType="autocomplete"
                options={[
                  { label: "All", value: "" },
                  { label: "Male", value: "male" },
                  { label: "Female", value: "female" },
                ]}
                value={props.category?.gender}
                control={control}
                name="gender"
                label={"gender"}
              />
            )}

            {!props.parentForKids && (
              <FormInputText
                rules={{}}
                inputType="autocomplete"
                options={[
                  { label: "True", value: "true" },
                  { label: "False", value: "false" },
                ]}
                value={props.category?.forKids}
                control={control}
                name="forKids"
                label={"forKids"}
              />
            )}

            {!props.parentSize && (
              <FormInputText
                rules={{}}
                inputType="autocomplete"
                options={[
                  { label: "None", value: "none" },
                  { label: "Clothing", value: "clothing" },
                  { label: "Footwear", value: "footwear" },
                  { label: "Arts", value: "arts" },
                  { label: "Furniture", value: "furniture" },
                ]}
                value={props.category?.size}
                control={control}
                name="sizeType"
                label={"size Type"}
              />
            )}

            {error && <Alert severity="error">{error}</Alert>}

            <CustomButton
              text={props.category ? "Edit" : "Create"}
              onClick={handleSubmit(onSubmit)}
            />
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default AddUpdateCategory;
