import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";

import SnackbarErrorClient from "../../Components/SnackbarError/SnackbarErrorClient";

import TableList from "../../Components/Table/Table";
import { FaExternalLinkAlt } from "react-icons/fa";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { ReviewStatus } from "../../Data/Enums";
import ReviewReview from "../../Components/ReviewReview/ReviewReview";
import ProductPopup from "../../Components/ProductPopup/ProductPopup";
import { usePaginationManager } from "../../Hooks/usePaginationManager";
import ReviewService from "../../Services/ReviewService";
import { BasePage } from "../../Layout/BasePage";
import { PageUpper } from "../../Layout/PageUpper";
import { PageTitle } from "../../Layout/PageTitle";
import { useBasicDetails } from "../../Hooks/useBasicDetails";
import GenericPopup from "../../Components/GenericPopup/GenericPopup";

const ReviewList: React.FunctionComponent = () => {
  const [selectedReview, setSelectedReview] = useState(null);
  const [selectedReviewId, setSelectedReviewId] = useState("");
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [oenProductModal, setOpenProductModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState("");

  const {
    items,
    setItems,
    dataGridApiRef,
    count,
    setCount,
    status,
    setStatus,
    httpClient,
  } = usePaginationManager();


  const {
    fetchBasicDetails: fetchProductBasicDetails,
    getBasicDetailsHttpClient: getProductBasicDetailsClient,
    basicDetailsData: productBasicDetailsData,
    openBasicDetailsPopup: openProductBasicDetailsPopup, 
    setOpenBasicDetailsPopup: setOpenProductBasicDetailsPopup
    } =
    useBasicDetails({
      baseUrl: "/owner/product/basic-details",
      method: "get"
  });

  let reviewStatuses = [
    { name: ReviewStatus.Pending, reviews: [], needsLoading: true, count: 0 },
    {
      name: ReviewStatus.Accepted,
      reviews: [],
      needsLoading: true,
      count: 0,
    },
    {
      name: ReviewStatus.Rejected,
      reviews: [],
      needsLoading: true,
      count: 0,
    },
  ];

  let columns: GridColDef[] = [
    {
      field: "reviewNumber",
      headerName: "Review Number",
      width: 150,
    },
    {
      field: "customerUserId",
      headerName: "User Id",
      width: 250,
      editable: false,
    },
    {
      field: "userName",
      headerName: "User Name",
      width: 150,
      editable: true,
    },
    {
      field: "rating",
      headerName: "Rating",
      width: 100,
      editable: true,
    },
    {
      field: "productId",
      headerName: "Product",
      width: 250,
      editable: false,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={async () => {
            await fetchProductBasicDetails(params.row.productId);
            setOpenProductBasicDetailsPopup(true);
          }}
        >
          {params.row.productId}
        </div>
      ),
    },

    {
      field: "date",
      headerName: "Date Created",
      width: 100,
      editable: true,
      renderCell: (params) => {
        return <div>{new Date(params.row.createdAt).toLocaleDateString()}</div>;
      },
    },
    {
      field: "Review",
      headerName: "Review",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <FaExternalLinkAlt
          onClick={() => {
            setSelectedReview(params.row);
            setSelectedReviewId(params.row.id);
            setOpenReviewModal(true);
          }}
        />
      ),
    },
  ];

  const getReviews = async () => {
    await ReviewService.getItems({
      status: status,
      statuses: reviewStatuses,
      getCount: true,
      setCount: setCount,
      items: items,
      setItems: setItems,
      httpClient: httpClient,
      dataGridApiRef: dataGridApiRef,
      requestUrl: "/owner/reviews",
      requestMethod: "get",
      responseField: "content",
    });
  };

  useEffect(() => {
    getReviews();
  }, [status, openReviewModal]);

  return (
    <BasePage snackBarErrorClients={[httpClient]}>

      <GenericPopup
        open={openProductBasicDetailsPopup}
        setOpen={setOpenProductBasicDetailsPopup}
        data={productBasicDetailsData || {}}
      />
      
      {openReviewModal && ( // Ensure openLocationModal is defined before rendering ProductPopup
        <ReviewReview
          getReviews={getReviews}
          review={selectedReview}
          reviewId={selectedReviewId}
          open={openReviewModal}
          setOpen={setOpenReviewModal}

        />
      )}

      {oenProductModal && ( // Ensure openLocationModal is defined before rendering ProductPopup
        <ProductPopup
          productId={selectedProductId}
          open={oenProductModal}
          setOpen={setOpenProductModal}
        />
      )}

      <div
        style={{
          display: "flex",
          gap: "16px",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <p style={{ marginBottom: 0 }} className="pageTitle"></p>
      </div>

      <PageUpper>
        <PageTitle title="Reviews"/>
        <div className="pageUpperCTAs">
          {reviewStatuses.map((object, i) => (
            <CustomButton
              key={i}
              text={object.name}
              onClick={() => {
                setItems([]);
                setStatus(i);
                dataGridApiRef.current.setPage(0);
              }}
              isSecondary={status === i ? false : true}
            />
          ))}
        </div>
      </PageUpper>

      <TableList
        rows={items}
        isLoading={httpClient.isLoading}
        columns={columns}
        rowsCount={count}
        getItems={() => getReviews()}
        dataGridApiRef={dataGridApiRef}
        showRowNumbers
        rowNumbersDescending
      />
    </BasePage>
  );
};

export default ReviewList;
