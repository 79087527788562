import { useState, useEffect, useCallback } from "react";
import imageCompression from "browser-image-compression";
import { useHttpClient } from "./HttpHook";

interface useAltMultipleImageUploadManagerProps {
  getItems: Function;
  uploadImagesRequestUrl: string;
  uploadImagesRequestMethod: string;
  getExistingImagesRequestUrl?: string;
  getExistingImagesRequestMethod?: string;
  deleteImageRequestUrl?: string;
  deleteImageRequestMethod?: string;
}

const useAltMultipleImageUploadManager = (
  props: useAltMultipleImageUploadManagerProps
) => {

  const [zoom, setZoom] = useState(1);
  const [image, setImage] = useState({ preview: "", data: "" });
  const [existingImages, setExistingImages] = useState<string[]>([]);
  const [aspectRatio, setAspectRatio] = useState<number>(4 / 3);
  const [imageSrc, setImageSrc] = useState<any>(null);
  const [initImageSrc, setInitImageSrc] = useState<any>(null);

  const httpClient = useHttpClient();
  const [error, setError] = useState("");

  const handleImageUpload = useCallback(async (file: File) => {
    try {
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 500,
        useWebWorker: true,
      });

      const reader = new FileReader();
      reader.readAsDataURL(compressedFile);
      reader.onloadend = () => {
        setImage({ preview: reader.result as string, data: compressedFile.name });
        setImageSrc(compressedFile);
      };
    } catch (error) {
      console.error("Error during image compression:", error);
    }
  }, []);


  const readFileAsDataURL = (
    file: File
  ): Promise<{ preview: string; data: string }> => {
    const reader = new FileReader();
    reader.addEventListener("error", (err) =>
      console.error("File reading error:", err)
    );

    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        resolve({
          preview: reader.result as string,
          data: file.name,
        });
      };
      reader.onerror = () => reject(reader.error);
      reader.readAsDataURL(file);
    });
  };

  const getExistingImages = async (id: string) => {
    const response = await httpClient.sendRequest({
      url: props.getExistingImagesRequestUrl,
      method: props.getExistingImagesRequestMethod,
      body: {
        id: id,
      },
    });

    console.log(`MultipleImagePopup: fetching existing images`);

    console.log(response);

    if (response.images) {
      setExistingImages(response.images);
    }
  };

  useEffect(() => {
    setImage({ preview: "", data: "" });
    setImageSrc(null);
  }, []);

  useEffect(() => {
    // reset images and imageSrcs when component unmouts
    return () => {
        setImage({ preview: "", data: "" });
        setImageSrc(null);
    };
  }, []);

  useEffect(() => {
    // console.log(`useImageUploadManager`);
    // console.log(`\timages`);
    // console.log(imagesToUpload);
    // console.log(imageSrcs);
  });

  const refreshData = async (itemId: string) => {
    setImage({ preview: "", data: "" });
    setImageSrc(null);
    await getExistingImages(itemId);
  };



  const handleDeleteImage = async (imageUrl: string, itemId: string) => {

    try {
      const response = await httpClient.sendRequest({
        url: props.deleteImageRequestUrl,
        method: props.deleteImageRequestMethod,
        body: {
          id: itemId,
          imageUrl: imageUrl,
        },
      });

      console.log(response);

      await refreshData(itemId);
      
    } catch (error) {
      console.log(error);
    }
  };

  return {
    image,
    setImage,
    existingImages,
    initImageSrc,
    setInitImageSrc,
    setExistingImages,
    aspectRatio,
    setAspectRatio,
    httpClient,
    error,
    setError,
    handleDeleteImage,
    readFileAsDataURL,
    getExistingImages,
    refreshData,
    imageSrc,
    setImageSrc
  };
};

export default useAltMultipleImageUploadManager;
