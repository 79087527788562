import React, { useEffect, useMemo, useState } from "react";
import { useHttpClient } from "../../Hooks/HttpHook";
import SnackbarErrorClient from "../SnackbarError/SnackbarErrorClient";
import { Alert, Box, Modal } from "@mui/material";
import FormInputText from "../FormInputText/FormInputText";
import CustomButton from "../CustomButton/CustomButton";
import PageLoader from "../PageLoader";
import "../ReviewOrEditProduct/ReviewProduct.scss";
import { useForm } from "react-hook-form";
import { IDiscount } from "../../Models/IDiscount";

interface AddUpdatePromocodeProps {
  name?: string | null;
  promocode?: string | null;
  isActive?: boolean | null;
  discountType?: string | null;
  amount?: number | null;
  maxDiscountAmount?: number | null;
  discount?: IDiscount | null;

  open: any;
  setOpen: any;
  onSubmit: Function;
}

const AddUpdatePromocode: React.FunctionComponent<AddUpdatePromocodeProps> = (
  props
) => {
  const [error, setError] = useState("");
  const [isPercentage, setIsPercentage] = useState<boolean>(false);
  const handleClose = () => props.setOpen(false);

  const submitPromocodeClient = useHttpClient();

  let defValues = {};

  const { register, reset, handleSubmit, control, getValues, setValue } =
    useForm({
      defaultValues: useMemo(() => {
        return defValues;
      }, [props]),
    });

  const onPercentageScreen = () => {
    console.log("HIOOOO");
  };

  useEffect(() => {
    console.log("hi");
    setIsPercentage(false);
    reset(defValues);

    if (props.discount) {
      if (props.discount.isPercentage == true) {
        setIsPercentage(true);
      }
      reset({
        name: props.discount.name,
        promocode: props.discount.promocode,
        amount: props.discount.amount,
        isPercentage: isPercentage == true ?  {label: "true", value: "true"} : {label: "false", value: "false"},
        maxDiscountAmount: props.discount.maxDiscountAmount,
        isActive: props.discount.isActive == true ?  {label: "True", value: "true"} : {label: "false", value: "false"},
        onVendor: props.discount.onVendor == true ?  {label: "True", value: "true"} : {label: "false", value: "false"},

      });
    }
  }, [props]);

  const onSubmit = async () => {
    const values = control._formValues;

    try {
      if(props.discount){
        const response = await submitPromocodeClient.sendRequest({
          url: "/owner/discount/update",
          method: "put",
          body: {
            id: props.discount.id,
            name: values.name,
            type: "promocode",
            promocode: values.promocode,
            isActive: values.isActive?.value,
            isPercentage: values.isPercentage?.value,
            amount: values.amount,
            maxDiscountAmount: values.maxDiscountAmount,
            onVendor: values.onVendor,
          },
          headers: {
            headers: { "Content-Type": "multipart/form-data" },
            // withCredentials: true
          },
        });

      }else{
        const response = await submitPromocodeClient.sendRequest({
          url: "/owner/discount/create",
          method: "post",
          body: {
            name: values.name,
            type: "promocode",
            promocode: values.promocode,
            isActive: values.isActive?.value,
            isPercentage: values.isPercentage?.value,
            amount: values.amount,
            maxDiscountAmount: values.maxDiscountAmount,
            onVendor: false,
          },
          headers: {
            headers: { "Content-Type": "multipart/form-data" },
            // withCredentials: true
          },
        });
      }
     
      props.setOpen(false);
      props.onSubmit();
    } catch (e) {}
    console.log(values);
  };

  return (
    <div>
      <SnackbarErrorClient client={submitPromocodeClient} />

      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="reviewProductBox">
          <p className="pageTitle">{props.discount ?  "Edit" : "Create"} Promocode</p>

          <form className="reviewProduct" onSubmit={handleSubmit(onSubmit)}>
            <PageLoader loading={submitPromocodeClient.isLoading} />

            <FormInputText
              rules={{
                required: true,
              }}
              control={control}
              name="name"
              label={"name"}
            />

            <FormInputText
              rules={{
                required: true,
              }}
              control={control}
              name="promocode"
              label={"promocode"}
            />

            <FormInputText
              rules={{}}
              inputType="autocomplete"
              options={[
                { label: "true", value: "true" },
                { label: "false", value: "false" },
              ]}
              value={{ label: "false", value: "false" }}
              control={control}
              name="isPercentage"
              label={"Is Percentage"}
              onChange={(data: any) => {
                if (data && (data.value === true || data.value == "true")) {
                  setIsPercentage(true);
                } else {
                  setIsPercentage(false);
                }
                console.log(data.value);
              }}
            />
            <FormInputText
              rules={{
                required: true,
              }}
              control={control}
              type="number"
              // value={props.amount}
              name="amount"
              label={"Amount"}
            />

            {isPercentage === true && (
              <FormInputText
                rules={{
                  required: true,
                }}
                control={control}
                type="number"
                // value={props.sellRatePercentage}
                name="maxDiscountAmount"
                label={"Max Discount Amount"}
              />
            )}

            <FormInputText
              rules={{}}
              inputType="autocomplete"
              options={[
                { label: "True", value: "true" },
                { label: "False", value: "false" },
              ]}
              control={control}
              name="isActive"
              label={"Is Active"}
            />

            {/* <FormInputText
              rules={{}}
              inputType="autocomplete"
              options={[
                { label: "True", value: "true" },
                { label: "False", value: "false" },
              ]}
              value={{ label: "False", value: "false" }}
              control={control}
              name="onVendor"
              label={"Discount on vendor?"}
            /> */}

            {error && <Alert severity="error">{error}</Alert>}

            <CustomButton text={ props.discount ? "Edit" : "Create"} onClick={handleSubmit(onSubmit)} />
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default AddUpdatePromocode;
