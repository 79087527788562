import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { useHttpClient } from "../../Hooks/HttpHook";

import SnackbarErrorClient from "../../Components/SnackbarError/SnackbarErrorClient";
import { FaExternalLinkAlt, FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import TableList from "../../Components/Table/Table";

import { MenuItem, Select, Snackbar } from "@mui/material";
import OrderList from "../OrderList/OrderList";
import SearchBar from "../../Components/SearchBar/SearchBar";
import useSearchBar from "../../Hooks/useSearchBar";
import { IS_NODE_BACKEND } from "../../Shared/globalVar";
import { usePaginationManager } from "../../Hooks/usePaginationManager";
import OrderTransactionService from "../../Services/OrderTransactionService";
import { BasePage } from "../../Layout/BasePage";
import { PageUpper } from "../../Layout/PageUpper";
import { PageTitle } from "../../Layout/PageTitle";
import { useBasicDetails } from "../../Hooks/useBasicDetails";
import GenericPopup from "../../Components/GenericPopup/GenericPopup";

interface OrderTransactionListProps {}

const OrderTransactionList: React.FunctionComponent<
  OrderTransactionListProps
> = (props: OrderTransactionListProps) => {
  // const [orderTransactions, setOrderTransactions] = useState([]);
  // const getOrderTransactionsClient = useHttpClient();
  const [transactionId, setTransactionId] = useState(null);
  const [displayOrdersByOrderTransaction, setDisplayOrdersByOrderTransaction] =
    useState(false);

    const {
      items,
      setItems,
      dataGridApiRef,
      count,
      setCount,
      status,
      setStatus,
      httpClient,
    } = usePaginationManager();

  const {
    searchBarResults,
    setSearchBarResults,
    getSearchBarItemById,
  } = useSearchBar({
    getByIdRequestUrl: "/owner/ordertransaction",
    getByIdRequestMethod: "get",
    responseField: "content"
  });

  const {
    fetchBasicDetails: fetchCustomerUserBasicDetails,
    getBasicDetailsHttpClient: getCustomerUserBasicDetailsHttpClient,
    basicDetailsData: customerUserBasicDetailsData,
    openBasicDetailsPopup: openCustomerUserBasicDetailsPopup, 
    setOpenBasicDetailsPopup: setOpenCustomerUserBasicDetailsPopup
   } =
    useBasicDetails({
      baseUrl: "/owner/customeruser/basic-details",
      method: "get"
  });

  useEffect(() => {
    getOrderTransactions();
  }, []);

  const columns: GridColDef[] = [
    {
      field: IS_NODE_BACKEND ? "_id" : "id",
      headerName: "Order Transaction ID",
      width: 250,
      editable: true,
    },
    {
      field: "customerUser",
      headerName: "Customer ID",
      width: 250,
      editable: true,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={async () => {
            await fetchCustomerUserBasicDetails(params.row.customerUser);
            setOpenCustomerUserBasicDetailsPopup(true);
          }}
        >
          {params.row.customerUser}
        </div>
      ),
    },
    // {
    //   field: "statusHistory",
    //   headerName: "Status History",
    //   width: 250,
    //   editable: false,
    // },
    {
      field: "currentStatus",
      headerName: "Current Status",
      width: 120,
      editable: false,
    },
    {
      field: "currency",
      headerName: "Currency",
      width: 100,
      editable: false,
    },
    {
      field: "subtotalAmount",
      headerName: "Subtotal Amount",
      width: 150,
      editable: false,
    },
    {
      field: "deliveryFees",
      headerName: "Delivery Fees",
      width: 150,
      editable: false,
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      width: 150,
      editable: false,
    },
    {
      field: "orderItems",
      headerName: "View Order Items",
      width: 150,
      renderCell: (params) => (
        <FaExternalLinkAlt
          onClick={() => {
            console.log(params);
            setTransactionId(params.row._id);
            setDisplayOrdersByOrderTransaction(true);
          }}
        />
      ),
    },
  ];

  const getOrderTransactions = async (getCount: boolean = true) => {

      await OrderTransactionService.getItems({
        status: status,
        getCount: getCount,
        setCount: setCount,
        items: items,
        setItems: setItems,
        httpClient: httpClient,
        dataGridApiRef: dataGridApiRef,
        requestUrl: "/owner/ordertransaction",
        requestMethod: "get",
        responseField: "content"
      });

  };

  return displayOrdersByOrderTransaction ? (
    // show the orders page filtered by order transaction

    <OrderList
      canChangeStatus={false}
      transactionId={transactionId}
      returnToOriginalComponent={() => {
        setDisplayOrdersByOrderTransaction(false);
      }}
    />
  ) : (
    // otherwise, show the main OrderTransactions page

    <BasePage snackBarErrorClients={[httpClient]}>

      <PageUpper>
        <PageTitle title="Order Transactions"/>
      </PageUpper>

      <SearchBar
        fetchDataById={getSearchBarItemById}
        searchBarResults={searchBarResults}
        setSearchBarResults={setSearchBarResults}
      />

      <GenericPopup
        open={openCustomerUserBasicDetailsPopup}
        setOpen={setOpenCustomerUserBasicDetailsPopup}
        data={customerUserBasicDetailsData || {}}
      />

      <TableList
        rows={
          searchBarResults.length > 0 ? searchBarResults : items
        }
        isLoading={httpClient.isLoading}
        dataGridApiRef={dataGridApiRef}
        columns={columns}
        rowsCount={count}
        showRowNumbersNonPaginated
        rowNumbersDescending
      />
    </BasePage>
  );
};

export default OrderTransactionList;
