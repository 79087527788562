import axios from '../Api/axios';
import { IAuth } from '../Models/IAuth';
import { IS_NODE_BACKEND } from '../Shared/globalVar';
import useAuth from './useAuth';

const useRefreshToken = () => {
    const { setAuth } = useAuth() as any;

    const REFRESH_URL = "/owner/owneruser/refresh";

    const refresh = async () => {
        
        console.log('in refreshhh')
        
        const response = await axios.get(REFRESH_URL, {
            withCredentials: true
        });

        setAuth((prev: IAuth ) => {
            console.log(`useRefreshToken - refresh() - current auth: `)
            console.log(prev);
            
            console.log(`useRefreshToken - refresh() - response.data.accessToken: `)
            console.log(response.data.accessToken);

            return { ...prev, 
                permissions: response.data.permissions,
                accessToken: response.data.accessToken }
        });

        return response.data.accessToken;
    }
    return refresh;
};

export default useRefreshToken;
