import React, { useEffect, useState } from "react";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { useHttpClient } from "../../Hooks/HttpHook";
import SnackbarErrorClient from "../../Components/SnackbarError/SnackbarErrorClient";
import { ProductStatus } from "../../Data/Enums";
import ReviewOrEditProduct from "../../Components/ReviewOrEditProduct/ReviewOrEditProduct";
import TableList from "../../Components/Table/Table";
import formatDate from "../../Utils/formatDate";
import SearchBar from "../../Components/SearchBar/SearchBar";
import { FaArrowLeft, FaExternalLinkAlt, FaTrash } from "react-icons/fa";
import VendorList from "../VendorList/VendorList";
import ImagePopup from "../../Components/ImagePopup/ImagePopup";
import MultipleImagePopup from "../../Components/MultipleImagePopup/MultipleImagePopup";
import { Button, SnackbarContent } from "@mui/material";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { FileUpload } from "@mui/icons-material";
import * as XLSX from 'xlsx';
import ProductExcelImportHelper from "../../Utils/ProductExcelImportHelper";
import { useDeleteHandler } from "../../Hooks/useDeleteHandler";
import DeletePopup from "../../Components/DeletePopup/DeletePopup";
import { IS_NODE_BACKEND, PAGE_SIZE } from "../../Shared/globalVar";
import { MenuItem, Select } from "@mui/material";
import usePaginationUtil from "../../Hooks/usePaginationUtil";
import usePopupManager from "../../Hooks/usePopupManager";
import useSearchBar from "../../Hooks/useSearchBar";
import { usePaginationManager } from "../../Hooks/usePaginationManager";
import ProductService from "../../Services/ProductService";
import SingleImageUploadPopup from "../../Components/SingleImageUploadPopup/SingleImageUploadPopup";
import { BasePage } from "../../Layout/BasePage";
import { PageUpper } from "../../Layout/PageUpper";
import { PageTitle } from "../../Layout/PageTitle";
import { useBasicDetails } from "../../Hooks/useBasicDetails";
import GenericPopup from "../../Components/GenericPopup/GenericPopup";

interface ProductListProps {
  vendorId?: string | null;
  returnToOriginalComponent?: () => void;
}

const ProductList: React.FunctionComponent<ProductListProps> = (
  props: ProductListProps
) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [openReview, setOpenReview] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageModalSrcs, setImageModalSrcs] = useState<string[]>([]);

  const uploadExcelProductsClient = useHttpClient();
  const [editingProduct, setEditingProduct] = useState(false);

  const { openDeletePopup, setOpenDeletePopup, handleDeleteClick, deleteItem, deleteClient } = useDeleteHandler({
    requestUrl: "/owner/product/delete",
    requestMethod: "post",
    onSuccess: async () => {
      await getProducts(true);
    },
  });

  const {
    items,
    setItems,
    dataGridApiRef,
    count,
    setCount,
    status,
    setStatus,
    httpClient
  } = usePaginationManager();


  const [openAddImagesPopup, setOpenAddImagesPopup] = usePopupManager();


  const {
    searchBarResults,
    setSearchBarResults,
    getSearchBarItemById
  } = useSearchBar({
    getByIdRequestUrl: "/owner/product/get",
    getByIdRequestMethod: "post",
    responseField: "content"
  });

  const {
    fetchBasicDetails: fetchVendorBasicDetails,
    getBasicDetailsHttpClient: getVendorBasicDetailsHttpClient,
    basicDetailsData: vendorBasicDetailsData,
    openBasicDetailsPopup: openVendorBasicDetailsPopup, 
    setOpenBasicDetailsPopup: setOpenVendorBasicDetailsPopup
   } =
    useBasicDetails({
      baseUrl: "/owner/vendor/basic-details",
      method: "get"
    });


  let productStatuses: any[] = [
    { name: ProductStatus.Pending, products: [], needsLoading: true, count: 0 },
    {
      name: ProductStatus.Accepted,
      products: [],
      needsLoading: true,
      count: 0,
    },
    {
      name: ProductStatus.Rejected,
      products: [],
      needsLoading: true,
      count: 0,
    },
  ];

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 315, editable: true },
    {
      field: "images",
      headerName: "Images",
      width: 90,
      renderCell: (params) =>
        params.row.images && (
          <img
            width={120}
            height={90}
            src={params.row.images[0]}
            alt="image"
            onClick={() => {
              console.log("opening image popup");
              setOpenImageModal(true);
              setImageModalSrcs(params.row.images);
            }}
          />
        ),
    },
    {
      field: "review",
      headerName: "Review",
      width: 70,
      editable: true,
      renderCell: (params) => (
        <FaExternalLinkAlt
          onClick={() => {
            handleReviewClick(params);
          }}
        />
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      width: 70,
      editable: true,
      renderCell: (params) => (
        <FaExternalLinkAlt
          onClick={() => {
            handleEditClick(params);
          }}
        />
      ),
    },
    {
      field: "addImages",
      headerName: "Add Images",
      width: 90,
      editable: true,
      renderCell: (params) => (
        <FaExternalLinkAlt
          onClick={() => {
            handleAddImagesClick(params);
          }}
        />
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      editable: true,
    },
    {
      field: "nameAr",
      headerName: "Name AR",
      width: 200,
      editable: true,
    },
    {
      field: "price",
      headerName: "Price",
      width: 100,
      editable: true,
    },
    {
      field: "forKids",
      headerName: "For Kids",
      width: 100,
      editable: true,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 100,
      editable: true,
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 60,
      renderCell: (params) => (
        <FaTrash
          onClick={() => {
            handleDeleteClick(params.row.id);
          }}
        />
      ),
    },
    {
      field: "discountedPrice",
      headerName: "Discounted Price",
      width: 100,
      editable: true,
    },
    {
      field: "categoryName",
      headerName: "Category Name",
      width: 200,
      editable: true,
    },
    {
      field: "vendor",
      headerName: "Vendor Id",
      width: 250,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={async () => {
            await fetchVendorBasicDetails(params.row.vendor);
            setOpenVendorBasicDetailsPopup(true);
          }}
        >
          {params.row.vendor}
        </div>
      ),
    },
    {
      field: "vendorName",
      headerName: "Vendor Name",
      width: 150,
      editable: true
    },
    {
      field: "sku",
      headerName: "SKU",
      width: 100,
      editable: true
    },
    {
      field: "isHidden",
      headerName: "Is Hidden",
      width: 130,
      editable: true,
      renderCell: (params) => (
        <Select
          value={params.row.isHidden}
          onChange={async (e) => {
            console.log(
              `Updating isHidden status. New status: ${e.target.value}`
            );

            await toggleProductIsHiddenStatus(params.row.id, e.target.value);
            await getProducts(true);

          }}
        >
          <MenuItem value="true">True</MenuItem>
          <MenuItem value="false">False</MenuItem>
        </Select>
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 190,
      editable: true,
      valueGetter: (params) => formatDate(params.value),
    },
  ];

  const getProducts = async (getCount: boolean) => {
    
    setSelectedProduct(null);

    await ProductService.getItems({
      status: status,
      statuses: productStatuses,
      getCount: getCount,
      setCount: setCount,
      items: items,
      setItems: setItems,
      httpClient: httpClient,
      dataGridApiRef: dataGridApiRef,
      requestUrl: "/owner/product/get",
      requestMethod: "post",
      responseField: "content"
    });
  };

  const getProductsByVendor = async (vendorId: string) => {
    try {
      const url = `/owner/product/get?vendorId=${vendorId}`
      const response = await httpClient.sendRequest({
        url: url,
        method: "post",
        body: {
          vendorId: vendorId,
        },
        headers: {
          headers: { "Content-Type": "application/json" },
          // withCredentials: true
        },
      });

      

        if (response && response.content) {
          setItems(response.content);
          setCount(response.totalElements);
        }

      
    } catch (error) {
      console.log("Error fetching products by vendor:", error);
      console.log(httpClient.error);
    }
  };

  const importProductsFromExcel = async () => {
    try {

      const excelData = await ProductExcelImportHelper.getJSONFromExcelFile();
  
      await createProductsFromExcel(excelData);
  
    } catch (err) {
      console.log("Error while importing products data from excel");
      console.log(err);
    }
  };
  

  const createProductsFromExcel = async(excelData: any[]) => {
    try {

      const response = await uploadExcelProductsClient.sendRequest({
        url: "/owner/product/create/bulk",
        method: "post",
        body: {
          excelData: excelData
        },
        headers: {
          headers: { "Content-Type": "application/json" },
          // withCredentials: true
        },
      });

      console.log("Attempting to create products from excel");

      if (response) {
        console.log("Products created from excel successfully");
        console.log(response);
      }

      await getProducts(true);

    } catch (error) {
      console.log("Error fetching products by vendor:", error);
      console.log(httpClient.error);
    }
  }
  
  const toggleProductIsHiddenStatus = async (productId: string, status?: boolean) => {

    await ProductService.updateItem({
      httpClient: httpClient,
      requestUrl: `/owner/product/ishidden`,
      requestMethod: "post",
      requestBody: {
        productId: productId
      },
      queryStrings: [`status=${status}`]
    });
  };

  useEffect(() => {
    // getProducts(true);
    if (props.vendorId) {
      getProductsByVendor(props.vendorId);
    } else {
      getProducts(true);
    }
  }, [status]);

  const handleReviewClick = (data: any) => {
    console.log(data);
    console.log(data.row);
    // setSelectedProduct(null);

    setTimeout(() => {
      setOpenReview(true);
      setSelectedProduct(data.row);
    }, 50);
  };

  const handleEditClick = (data: any) => {
    console.log("Editing product")

    setTimeout(() => {
      setEditingProduct(true);
      setOpenReview(true);
      setSelectedProduct(data.row);
    }, 50);
  };

  const handleAddImagesClick = (data: any) => {

    setTimeout(() => {
      setOpenAddImagesPopup(true);
      setSelectedProduct(data.row);
    }, 50);
  };


  return (
    // show the main Products page

    <BasePage snackBarErrorClients={[httpClient, uploadExcelProductsClient]}>

      <MultipleImagePopup
        imageSrcs={imageModalSrcs}
        open={openImageModal}
        setOpen={setOpenImageModal}
      />

      <SingleImageUploadPopup
        open={openAddImagesPopup}
        item={selectedProduct}
        setOpen={setOpenAddImagesPopup}
        getItems={async() =>{await getProducts(true)}}
        uploadImagesRequestUrl="/owner/product/image"
        uploadImagesRequestMethod="post"
        getExistingImagesRequestUrl="/owner/product/getimages"
        getExistingImagesRequestMethod="post"
        deleteImageRequestUrl="owner/product/delete/image"
        deleteImageRequestMethod="post"
      />

      <GenericPopup
        open={openVendorBasicDetailsPopup}
        setOpen={setOpenVendorBasicDetailsPopup}
        data={vendorBasicDetailsData || {}}
      />

      <DeletePopup
        open={openDeletePopup}
        setOpen={setOpenDeletePopup}
        deleteFunction={deleteItem}
        deleteHttpClient={deleteClient}
      />

      <ReviewOrEditProduct
        getProducts={getProducts}
        open={openReview}
        setOpen={setOpenReview}
        product={selectedProduct}
        isEditing={editingProduct}
        toggleIsEditing={()=>{setEditingProduct(false)}}
      />

      <PageUpper>
        
        <PageTitle title="Products"/>

        <div className="pageUpperCTAs">
          {productStatuses.map((object, i) => (
            <CustomButton
              key={i}
              text={object.name}
              onClick={() => {
                setCount(0);
                setItems([]);
                setStatus(i);
                dataGridApiRef.current.setPage(0);
              }}
              isSecondary={status === i ? false : true}
            />
          ))}
        </div>
      </PageUpper>

      {props.returnToOriginalComponent && (
        // if called from another component, display arrow to return to that component
        <FaArrowLeft onClick={props.returnToOriginalComponent} />
      )}

      <SearchBar
        fetchDataById={getSearchBarItemById}
        searchBarResults={searchBarResults}
        setSearchBarResults={setSearchBarResults}
      />

      <div style={{ display: "flex", gap: "10px" }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FileUploadIcon />}
          style={{ width: "200px" }}
          onClick={async () => {
            // await exportToExcel();
            await importProductsFromExcel();
          }}
        >
          Import From Excel
        </Button>
      </div>

      <TableList
        // onRowClick={handleEvent}
        rows={searchBarResults.length > 0 ? searchBarResults : items}
        isLoading={httpClient.isLoading}
        columns={columns}
        rowsCount={count}
        getItems={() => getProducts(true)}
        dataGridApiRef={dataGridApiRef}
        showRowNumbers
        rowNumbersDescending
      />
    </BasePage>
  );
};

export default ProductList;