import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Modal,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { IProduct } from "../../Models/IProduct";
import FormInputText from "../FormInputText/FormInputText";
import { useForm } from "react-hook-form";
import "./ReviewProduct.scss";
import CustomButton from "../CustomButton/CustomButton";
import { useHttpClient } from "../../Hooks/HttpHook";
import SnackbarErrorClient from "../SnackbarError/SnackbarErrorClient";
import PageLoader from "../PageLoader";

interface ReviewOrEditProductProps {
  product?: IProduct | null;
  isEditing?: boolean;
  toggleIsEditing?: any;
  open: any;
  setOpen: any;
  getProducts: any;
}

const ReviewOrEditProduct: React.FunctionComponent<ReviewOrEditProductProps> = (
  props
) => {
  
  const [forKids, setForKids] = useState<boolean>(false);
  const [gender, setGender] = useState<String>();
  
  const [error, setError] = useState("");
  
  const handleClose = () => {
    props.setOpen(false);

    if (props.isEditing && props.toggleIsEditing) {
      props.toggleIsEditing();
    }
  };

  const reviewProductClient = useHttpClient();

  const defaultValues = useMemo(() => ({
    id: props.product?.id ?? "",
    name: props.product?.name ?? "",
    price: props.product?.price ?? null,
    description: props.product?.description ?? "",
    categoryName: props.product?.categoryName ?? "",
    categoryId: props.product?.categoryId ?? "",
    gender: props.product?.gender ? { 
      label: props.product.gender.charAt(0).toUpperCase() + props.product.gender.slice(1), 
      value: props.product.gender 
    } : { label: "All", value: "all" },
    forKids: props.product?.forKids !== undefined ? {
      label: props.product.forKids ? "True" : "False",
      value: props.product.forKids
    } : null,
    sku: props.product?.sku ?? "",
    reason: "",
  }), [props.product]);

  const { register, reset, handleSubmit, control, getValues } = useForm({
    defaultValues
  });

  useEffect(() => {
    setError("");
    setForKids(props.product?.forKids === true);
    setGender(props.product?.gender);

    if (props.product) {
      reset(defaultValues);
    }
  }, [props.product, defaultValues, reset]);

  const reviewProduct = async (id: any, decision: any) => {
    setError("");
    const reason = control._formValues.reason;

    console.log("REASON" + reason + "REASON");

    if (decision === "reject" && (!reason || reason === " ")) {
      setError("Reason required");

      console.log("reson stuff");

      return;
    }

    try {
      const response = await reviewProductClient.sendRequest({
        url: "/owner/product/review",
        method: "post",
        body: {
          id,
          decision,
          reason,
        },
        headers: {
          headers: { "Content-Type": "application/json" },
          // withCredentials: true
        },
      });
      console.log(response);
      props.setOpen(false);
      props.getProducts(true, true);
    } catch (e) {
      console.log(reviewProductClient.error);
    }
  };

  const editProduct = async (product: IProduct | null) => {
    const values = control._formValues;

    try {
      const response = await reviewProductClient.sendRequest({
        url: "/owner/product/edit",
        method: "post",
        body: {
          id: values.id,
          name: values.name,
          price: values.price,
          description: values.description,
          gender: values.gender?.value || null,
          forKids: values.forKids?.value ?? null,
          sku: values.sku,
          categoryId: values.categoryId,
        },
        headers: {
          headers: { "Content-Type": "application/json" },
        },
      });
      console.log(response);
      props.setOpen(false);
      props.getProducts(true, true);
    } catch (e) {
      console.log(reviewProductClient.error);
    }
  };

  return (
    <div>
      <SnackbarErrorClient client={reviewProductClient} />

      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="reviewProductBox">
          <p className="pageTitle">
            {props.isEditing ? "Edit" : "Review"} Product
          </p>

          {props.product && (
            <form className="reviewProduct">
              <PageLoader loading={reviewProductClient.isLoading} />

              <FormInputText
                rules={{}}
                disabled={true}
                control={control}
                name="id"
                label={"Id"}
              />
              <FormInputText
                rules={{}}
                disabled={props.isEditing ? false : true}
                control={control}
                name="name"
                label={"Name"}
              />
              <FormInputText
                rules={{}}
                disabled={props.isEditing ? false : true}
                control={control}
                name="price"
                type="number"
                label={"price"}
              />
              <FormInputText
                rules={{}}
                disabled={props.isEditing ? false : true}
                control={control}
                name="description"
                label={"description"}
                multiline={true}
              />
              <FormInputText
                rules={{}}
                disabled={true}
                control={control}
                name="categoryName"
                label={"Category Name"}
              />
              <FormInputText
                rules={{}}
                disabled={props.isEditing ? false : true}
                control={control}
                inputType="autocomplete"
                options={[
                  { label: "All", value: "all" },
                  { label: "Male", value: "male" },
                  { label: "Female", value: "female" },
                ]}
                name="gender"
                label={"gender"}
                value={gender}
              />

              <FormInputText
                rules={{}}
                disabled={props.isEditing ? false : true}
                // disabled={true}
                inputType="autocomplete"
                options={[
                  { label: "True", value: true },
                  { label: "False", value: false },
                ]}
                control={control}
                name="forKids"
                label={"forKids"}
                value={forKids}
              />

              <FormInputText
                rules={{}}
                disabled={props.isEditing ? false : true}
                control={control}
                name="sku"
                label={"sku"}
              />

              {props.isEditing && (
                <FormInputText
                  rules={{}}
                  control={control}
                  name="categoryId"
                  label={"Category ID"}
                />
              )}

              {!props.isEditing && (
                <FormInputText
                  rules={{}}
                  control={control}
                  name="reason"
                  label={"reason"}
                />
              )}

              {error && <Alert severity="error">{error}</Alert>}

              {props.product &&
                props.product.images &&
                props.product.images.length > 0 && (
                  <div className="container">
                    {props.product.images.map((object, i) => (
                      <img className="productImage" src={object.toString()} />
                    ))}
                  </div>
                )}

              {!props.isEditing && (
                <CustomButton
                  text={"Accept"}
                  onClick={() => reviewProduct(props.product?.id, "ACCEPTED")}
                />
              )}

              {!props.isEditing && (
                <CustomButton
                  text={"Reject"}
                  isSecondary={true}
                  onClick={() => reviewProduct(props.product?.id, "REJECTED")}
                />
              )}

              {props.isEditing && (
                <CustomButton
                  text={"Edit"}
                  onClick={() => editProduct(props.product ?? null)}
                />
              )}
            </form>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default ReviewOrEditProduct;
