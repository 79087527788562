import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { useHttpClient } from "../../Hooks/HttpHook";

import SnackbarErrorClient from "../../Components/SnackbarError/SnackbarErrorClient";
import { FaEdit, FaTrash } from "react-icons/fa";

import { IDiscount } from "../../Models/IDiscount";
import AddUpdatePromocode from "../../Components/AddUpdatePromocode/AddUpdatePromocode";
import TableList from "../../Components/Table/Table";
import DeletePopup from "../../Components/DeletePopup/DeletePopup";
import { IS_NODE_BACKEND } from "../../Shared/globalVar";
import { usePaginationManager } from "../../Hooks/usePaginationManager";
import { useDeleteHandler } from "../../Hooks/useDeleteHandler";
import PromocodeService from "../../Services/PromocodeService";
import usePopupManager from "../../Hooks/usePopupManager";
import { BasePage } from "../../Layout/BasePage";
import { PageUpper } from "../../Layout/PageUpper";
import { PageTitle } from "../../Layout/PageTitle";


interface PromocodeListProps {}

const PromocodeList: React.FunctionComponent<PromocodeListProps> = () => {

  const {
    items,
    setItems,
    dataGridApiRef,
    count,
    setCount,
    status,
    setStatus,
    httpClient
  } = usePaginationManager();

  const { openDeletePopup, setOpenDeletePopup, handleDeleteClick, deleteItem, deleteClient } = useDeleteHandler({
    requestUrl: "/owner/discount",
    requestMethod: "delete",
    idIsPathVariable: true,
    onSuccess: async () => {
      await getPromocodes();
    },
  });


  // const [discounts, setDiscounts] = useState<IDiscount[]>([]);
  // const getPromocodesClient = useHttpClient();
  const [openAddUpdateModal, setOpenAddUpdateModal] = usePopupManager();
  const [selectedDiscount, setSelectedDiscount] = useState<IDiscount | null>(null);
  const deleteDiscountClient = useHttpClient();
  // const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const [selectedDiscountToDelete, setSelectedDiscountToDelete] = useState<IDiscount | null>(null);

  useEffect(() => {
    getPromocodes();
  }, []);

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 275, editable: true },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      editable: true,
    },
    {
      field: "type",
      headerName: "Type",
      width: 200,
      editable: true,
    },
    {
      field: "isPercentage",
      headerName: "Is Percentage?",
      width: 200,
      editable: true,
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 200,
      editable: true,
    },
    {
      field: "maxDiscountAmount",
      headerName: "Max Discount Amount",
      width: 200,
      editable: true,
    },
    {
      field: "onVendor",
      headerName: "On Vendor",
      width: 200,
      editable: true,
    },
    {
      field: "update",
      headerName: "Update",
      width: 120,
      renderCell: (params) => (
        <FaEdit
          onClick={() => {
            setSelectedDiscount(params.row);
            setOpenAddUpdateModal(true);
          }}
        />
      ),
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 60,
      renderCell: (params) => (
        <FaTrash
          onClick={() => {
            handleDeleteClick(params.row.id);
          }}
        />
      ),
    },
  ];

  const getPromocodes = async () => {

    await PromocodeService.getItems({
      status: status,
      getCount: true,
      setCount: setCount,
      items: items,
      setItems: setItems,
      httpClient: httpClient,
      dataGridApiRef: dataGridApiRef,
      requestUrl: "/owner/discount/promocode",
      requestMethod: "get",
      responseField: "content",
    });
  };
  


  return (
    <BasePage snackBarErrorClients={[httpClient]}>
     
      <AddUpdatePromocode
        open={openAddUpdateModal}
        discount={selectedDiscount}
        
        setOpen={setOpenAddUpdateModal}
        onSubmit={() => {
          setSelectedDiscount(null);
          getPromocodes();
          
        }}
      />
      
      <DeletePopup
        open={openDeletePopup}
        setOpen={setOpenDeletePopup}
        deleteFunction={deleteItem}
        deleteHttpClient={deleteClient}
      />

      <PageUpper>
        <PageTitle title="Promocodes"/>
        <CustomButton
          text="Add Promocode"
          onClick={() => {
            setOpenAddUpdateModal(true);
          }}
          isSecondary={true}
        />
      </PageUpper>

      <TableList
        rows={items}
        isLoading={httpClient.isLoading}
        columns={columns}
        getItems={() => getPromocodes()}
        rowsCount={count}
        dataGridApiRef={dataGridApiRef}
        showRowNumbers
        rowNumbersDescending
      />

    </BasePage>
  );
};

export default PromocodeList;
