import React, { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import { useHttpClient } from "../../Hooks/HttpHook";
import SnackbarErrorClient from "../../Components/SnackbarError/SnackbarErrorClient";
import { FaEye, FaExternalLinkAlt, FaArrowLeft } from "react-icons/fa";

import { defaultMaxListeners } from "events";
import { ICustomer } from "../../Models/ICustomer";
import TableList from "../../Components/Table/Table";
import CustomerLocationPopup from "../../Components/CustomerLocationPopup/CustomerLocationPopup";
import { ILocation } from "../../Models/ILocation";
import OrderList from "../OrderList/OrderList";
import { IProvince } from "../../Models/IProvince";
import { ICity } from "../../Models/ICity";
import { CollectionsBookmarkRounded } from "@mui/icons-material";
import SearchBar from "../../Components/SearchBar/SearchBar";
import useSearchBar from "../../Hooks/useSearchBar";
import { IS_NODE_BACKEND } from "../../Shared/globalVar";
import CustomerService from "../../Services/CustomerService";
import { usePaginationManager } from "../../Hooks/usePaginationManager";
import usePopupManager from "../../Hooks/usePopupManager";
import GenericPopup from "../../Components/GenericPopup/GenericPopup";
import { BasePage } from "../../Layout/BasePage";
import { PageTitle } from "../../Layout/PageTitle";
import { PageUpper } from "../../Layout/PageUpper";

interface CustomerListProps {}

const CustomerList: React.FunctionComponent<CustomerListProps> = () => {
  // const [customers, setCustomers] = useState<ICustomer[]>([]);

  // const getCustomersClient = useHttpClient();
  const getCustomerLocationClient = useHttpClient();
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [customerPurchaseHistoryId, setCustomerPurchaseHistoryId] =
    useState(null);
  const [customerLocation, setCustomerLocation] = useState<ILocation>();
  const [province, setProvince] = useState<IProvince>();
  const [city, setCity] = useState<ICity>();
  const [openCustomerLocationPopup, setOpenCustomerLocationPopup] = usePopupManager();
  const [customerLocationData, setCustomerLocationData] = useState({});

  const {
    items,
    setItems,
    dataGridApiRef,
    count,
    setCount,
    status,
    setStatus,
    httpClient
  } = usePaginationManager();

  const [displayOrdersByCustomer, setDisplayOrdersByCustomer] = useState(false);

  const {
    searchBarResults,
    setSearchBarResults,
    getSearchBarItemById
  } = useSearchBar({
    getByIdRequestUrl: "/owner/customeruser",
    getByIdRequestMethod: "get",
    bodyField: "customerId",
    responseField: "content"
  });

  useEffect(() => {
    getCustomers();
  }, []);


  const getCustomers = async (getCount: boolean = true) => {
    try {

        await CustomerService.getItems({
          status: status,
          // statuses: orderAdminStatuses,
          getCount: getCount,
          setCount: setCount,
          items: items,
          setItems: setItems,
          httpClient: httpClient,
          dataGridApiRef: dataGridApiRef,
          requestUrl: "/owner/customeruser",
          requestMethod: "get",
          responseField: "content"
        });
    
 
    } catch (e) {
      console.log(e);
      console.log(httpClient.error);
    }
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 320, editable: true },
    {
      field: "firstName",
      headerName: "First Name",
      width: 150,
      editable: true,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 120,
      editable: true,
    },
    {
      field: "email",
      headerName: "Email Address",
      width: 250,
      editable: true,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 250,
      editable: true,
    },
    {
      field: "deliveryAddress",
      headerName: "Delivery Address",
      width: 200,
      editable: true,
    },
    {
      field: "purchaseHistory",
      headerName: "Purchase History",
      width: 150,
      editable: true,
      renderCell: (params) => (
        <FaExternalLinkAlt
          onClick={() => {
            setCustomerPurchaseHistoryId(params.row.id);
            setDisplayOrdersByCustomer(true);
            console.log(
              `selected customer purchase history id: ` + params.row.id
            );
          }}
        />
      ),
    },
    {
      field: "location",
      headerName: "Location",
      width: 150,
      renderCell: (params) => (
        <FaExternalLinkAlt
          onClick={async () => {
            setCustomerLocation(undefined);
            setProvince(undefined);
            setCity(undefined);

            const data = await CustomerService.getCustomerLocation({
              customerLocationId: params.row.deliveryAddress,
              httpClient: httpClient
            });
            
            setCustomerLocationData(data ?? {});
            setOpenCustomerLocationPopup(true);
            // await getAggregatedCustomerLocation(params.row.deliveryAddress);
            // setOpenLocationModal(true);
          }}
        />
      ),
    },
    {
      field: "numberOfOrders",
      headerName: "Number of Orders",
      width: 150,
    },
    {
      field: "numberOfOrderTransactions",
      headerName: "Number of Order Transactions",
      width: 200,
    },
  ];

  return displayOrdersByCustomer ? (
    // show the orders page filtered by customer (purchase history)

    <OrderList
      canChangeStatus={false}
      customerId={customerPurchaseHistoryId}
      returnToOriginalComponent={() => {
        setDisplayOrdersByCustomer(false);
      }}
    />
  ) : (
    // otherwise, show the main Customers page

    <BasePage snackBarErrorClients={[httpClient]}>
      
      <PageUpper>
        <PageTitle title="Customers"/>
      </PageUpper>
      
      <SearchBar
        fetchDataById={getSearchBarItemById}
        searchBarResults={searchBarResults}
        setSearchBarResults={setSearchBarResults}
        customPlaceholderText="Enter Customer ID..."
      />

      <CustomerLocationPopup
        customerLocation={customerLocation}
        province={province}
        city={city}
        open={openLocationModal}
        setOpen={setOpenLocationModal}
      />
      
      <GenericPopup
        data={customerLocationData}
        open={openCustomerLocationPopup}
        setOpen={setOpenCustomerLocationPopup}
      />


      <TableList
        rows={searchBarResults.length > 0 ? searchBarResults : items}
        getItems={(p) => getCustomers()}
        isLoading={httpClient.isLoading}
        dataGridApiRef={dataGridApiRef}
        columns={columns}
        rowsCount={count}
        showRowNumbers
        rowNumbersDescending
      />
    </BasePage>
  );
};

export default CustomerList;
