import ApiService from "./ApiService";
import * as XLSX from "xlsx";

export default class OrderService extends ApiService {
  public static async exportToExcel({ httpClient }: { httpClient: any }) {
    
    const response = await this.sendRequest({
      httpClient: httpClient,
      url: "/owner/order-item/export/excel",
      method: "get",
    });

    console.log(`OrderService - exportToExcel - response`);
    console.log(response);
    const jsonData = response.data;

    if (response) {
      this.createExcelFile(jsonData, "Orders", "Orders.xlsx");
    } else {
      console.error(
        "no valid response received from the server for export to excel"
      );
    }
  }

  static async exportToCSV({ httpClient }: { httpClient: any }) {
    try {
      const response = await httpClient.sendRequest({
        url: "/owner/order-item/export/csv?descending=true",
        method: "post",
        body: null,
        responseType: "blob",
        headers: {
          headers: {
            // "Content-Type": "application/json",
            //withCredentials: true
          },
        },
      });
      if (response) {
        const blob = new Blob([response], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "orders.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        console.error(
          "no valid response received from the server for export to csv"
        );
      }
    } catch (e) {
      console.log(e);
      console.log(httpClient.error);
    }
  }

  static binaryStringToArrayBuffer(binaryString: string): ArrayBuffer {
    const arrayBuffer = new ArrayBuffer(binaryString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i) & 0xff;
    }
    return arrayBuffer;
  }

  static createExcelFile(
    data: any[],
    sheetName: string,
    fileName: string
  ): void {
    const worksheet = XLSX.utils.json_to_sheet(data);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);

    const excelBinaryString = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "binary",
    });

    const arrayBuffer = this.binaryStringToArrayBuffer(excelBinaryString);
    const blob = new Blob([arrayBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const downloadUrl = window.URL.createObjectURL(blob);
    const anchorElement = document.createElement("a");
    anchorElement.href = downloadUrl;
    // anchorElement.download = `cars.xlsx`;
    anchorElement.download = fileName;
    anchorElement.click();

    window.URL.revokeObjectURL(downloadUrl);
  }
}