import React, { useEffect, useState } from "react";
import { GridColDef, useGridApiRef } from "@mui/x-data-grid";
import { useHttpClient } from "../../Hooks/HttpHook";

import SnackbarErrorClient from "../../Components/SnackbarError/SnackbarErrorClient";

import TableList from "../../Components/Table/Table";
import { Button, MenuItem, Select } from "@mui/material";
import { FaArrowLeft } from "react-icons/fa";
import CustomerList from "../CustomerList/CustomerList";
import OrderTransactionList from "../OrderTransactionList/OrderTransactionList";
import { AdminStatusEnum, OrderListType } from "../../Data/Enums";
import ImagePopup from "../../Components/ImagePopup/ImagePopup";
import formatDate from "../../Utils/formatDate";
import SearchBar from "../../Components/SearchBar/SearchBar";
import CustomButton from "../../Components/CustomButton/CustomButton";
import { PAGE_SIZE } from "../../Shared/globalVar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useCustomHttpClient } from "../../Hooks/useCustomHttpClient";
import * as XLSX from "xlsx";
import usePaginationUtil from "../../Hooks/usePaginationUtil";
import useSearchBar from "../../Hooks/useSearchBar";
import { usePaginationManager } from "../../Hooks/usePaginationManager";
import OrderService from "../../Services/OrderService";
import { useBasicDetails } from "../../Hooks/useBasicDetails";
import GenericPopup from "../../Components/GenericPopup/GenericPopup";
import { BasePage } from "../../Layout/BasePage";

interface OrderListProps {
  canChangeStatus: boolean;
  vendorTransactionId?: string | null;
  transactionId?: string | null;
  customerId?: string | null;
  returnToOriginalComponent?: () => void;
}

const OrderList: React.FunctionComponent<OrderListProps> = (
  props: OrderListProps
) => {
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageModalSrc, setImageModalSrc] = useState("");

  const {
    items,
    setItems,
    dataGridApiRef,
    count,
    setCount,
    status,
    setStatus,
    httpClient,
  } = usePaginationManager();

  const {
    fetchBasicDetails: fetchCustomerUserBasicDetails,
    getBasicDetailsHttpClient: getCustomerUserBasicDetailsHttpClient,
    basicDetailsData: customerUserBasicDetailsData,
    openBasicDetailsPopup: openCustomerUserBasicDetailsPopup, 
    setOpenBasicDetailsPopup: setOpenCustomerUserBasicDetailsPopup
   } =
    useBasicDetails({
      baseUrl: "/owner/customeruser/basic-details",
      method: "get"
    });

    const {
      fetchBasicDetails: fetchVendorBasicDetails,
      getBasicDetailsHttpClient: getVendorBasicDetailsHttpClient,
      basicDetailsData: vendorBasicDetailsData,
      openBasicDetailsPopup: openVendorBasicDetailsPopup, 
      setOpenBasicDetailsPopup: setOpenVendorBasicDetailsPopup
     } =
      useBasicDetails({
        baseUrl: "/owner/vendor/basic-details",
        method: "get"
      });

    const {
      fetchBasicDetails: fetchOrderItemBasicDetails,
      getBasicDetailsHttpClient: getOrderItemBasicDetailsHttpClient,
      basicDetailsData: orderItemBasicDetailsData,
      openBasicDetailsPopup: openOrderItemBasicDetailsPopup, 
      setOpenBasicDetailsPopup: setOpenOrderItemBasicDetailsPopup
      } =
      useBasicDetails({
        baseUrl: "/owner/order-item/basic-details",
        method: "get"
      });


    const {
      fetchBasicDetails: fetchOrderBasicDetails,
      getBasicDetailsHttpClient: getOrderBasicDetailsClient,
      basicDetailsData: orderBasicDetailsData,
      openBasicDetailsPopup: openOrderBasicDetailsPopup, 
      setOpenBasicDetailsPopup: setOpenOrderBasicDetailsPopup
      } =
      useBasicDetails({
        baseUrl: "/owner/order/basic-details",
        method: "get"
      });

    const {
      fetchBasicDetails: fetchOrderTransactionBasicDetails,
      getBasicDetailsHttpClient: getOrderTransactionBasicDetailsClient,
      basicDetailsData: orderTransactionBasicDetailsData,
      openBasicDetailsPopup: openOrderTransactionBasicDetailsPopup, 
      setOpenBasicDetailsPopup: setOpenOrderTransactionBasicDetailsPopup
      } =
      useBasicDetails({
        baseUrl: "/owner/ordertransaction/basic-details",
        method: "get"
    });

    const {
      fetchBasicDetails: fetchCategoryBasicDetails,
      getBasicDetailsHttpClient: getCategoryBasicDetailsClient,
      basicDetailsData: categoryBasicDetailsData,
      openBasicDetailsPopup: openCategoryBasicDetailsPopup, 
      setOpenBasicDetailsPopup: setOpenCategoryBasicDetailsPopup
      } =
      useBasicDetails({
        baseUrl: "/owner/category/basic-details",
        method: "get"
    });

    const {
      fetchBasicDetails: fetchProductBasicDetails,
      getBasicDetailsHttpClient: getProductBasicDetailsClient,
      basicDetailsData: productBasicDetailsData,
      openBasicDetailsPopup: openProductBasicDetailsPopup, 
      setOpenBasicDetailsPopup: setOpenProductBasicDetailsPopup
      } =
      useBasicDetails({
        baseUrl: "/owner/product/basic-details",
        method: "get"
    });

  useEffect(() => {
    getOrders(true);
  }, [props.canChangeStatus, status]);

  const { searchBarResults, setSearchBarResults, getSearchBarItemById } =
    useSearchBar({
      getByIdRequestUrl: "/owner/order-item",
      getByIdRequestMethod: "post",
      responseField: "content",
    });

  let orderAdminStatuses = [
    {
      name: "All",
      orders: [],
      needsLoading: true,
      count: 0,
    },
    {
      name: AdminStatusEnum.Pending,
      orders: [],
      needsLoading: true,
      count: 0,
    },
    {
      name: AdminStatusEnum.Received,
      // name: "Received",
      orders: [],
      needsLoading: true,
      count: 0,
    },
    {
      name: AdminStatusEnum.Packed,
      // name: "Packed",
      orders: [],
      needsLoading: true,
      count: 0,
    },
    {
      name: AdminStatusEnum.Shipped,
      // name: "Shipped",
      orders: [],
      needsLoading: true,
      count: 0,
    },
    {
      name: AdminStatusEnum.Returned,
      orders: [],
      needsLoading: true,
      count: 0,
    },
  ];

  let columns: GridColDef[] = [
    {
      field: "productImage",
      headerName: "Product Image",
      width: 150,
      renderCell: (params) =>
        params.row.productImage && (
          <img
            width={100}
            height={120}
            src={params.row.productImage}
            alt="logo"
            onClick={() => {
              console.log("opening image popup");
              setOpenImageModal(true);
              setImageModalSrc(params.row.productImage);
            }}
          />
        ),
    },
    {
      field: "id",
      headerName: "Order Item ID",
      width: 230,
      editable: true,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={async () => {
            await fetchOrderItemBasicDetails(params.row.id);
            setOpenOrderItemBasicDetailsPopup(true);
          }}
        >
          {params.row.id}
        </div>
      ),
    },
    {
      field: "orderId",
      headerName: "Order ID",
      width: 230,
      editable: true,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={async () => {
            await fetchOrderBasicDetails(params.row.orderId);
            setOpenOrderBasicDetailsPopup(true);
          }}
        >
          {params.row.orderId}
        </div>
      ),
    },
    {
      field: "transactionId",
      headerName: "Transaction Id",
      width: 230,
      editable: true,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={async () => {
            await fetchOrderTransactionBasicDetails(params.row.transactionId);
            setOpenOrderTransactionBasicDetailsPopup(true);
          }}
        >
          {params.row.transactionId}
        </div>
      ),
    },
    {
      field: "productId",
      headerName: "Product ID",
      width: 120,
      editable: true,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={async () => {
            await fetchProductBasicDetails(params.row.productId);
            setOpenProductBasicDetailsPopup(true);
          }}
        >
          {params.row.productId}
        </div>
      ),
    },
    {
      field: "productName",
      headerName: "Product Name",
      width: 170,
      editable: true,
    },
    {
      field: "number",
      headerName: "Number",
      width: 70,
      editable: true,
    },
    {
      field: "categoryId",
      headerName: "Category ID",
      width: 130,
      editable: true,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={async () => {
            await fetchCategoryBasicDetails(params.row.categoryId);
            setOpenCategoryBasicDetailsPopup(true);
          }}
        >
          {params.row.categoryId}
        </div>
      ),
    },
    {
      field: "categoryName",
      headerName: "Category",
      width: 100,
      editable: true,
    },
    {
      field: "topLevelCategoryName",
      headerName: "Top Level Category",
      width: 130,
      editable: true,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 70,
      editable: false,
    },
    {
      field: "size",
      headerName: "Size",
      width: 20,
      editable: true,
    },
    {
      field: "price",
      headerName: "Price",
      width: 70,
      editable: true,
    },
    {
      field: "vendor",
      headerName: "Vendor ID",
      width: 225,
      editable: true,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={async () => {
            await fetchVendorBasicDetails(params.row.vendor);
            setOpenVendorBasicDetailsPopup(true);
          }}
        >
          {params.row.vendor}
        </div>
      ),
    },
    {
      field: "customerUserId",
      headerName: "Customer ID",
      width: 320,
      editable: true,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer", textDecoration: "underline" }}
          onClick={async () => {
            await fetchCustomerUserBasicDetails(params.row.customerUserId);
            setOpenCustomerUserBasicDetailsPopup(true);
          }}
        >
          {params.row.customerUserId}
        </div>
      ),
    },
    {
      field: "airwayBill",
      headerName: "Airway Bill",
      width: 120,
      editable: true,
    },
    {
      field: "color",
      headerName: "Color",
      width: 100,
      editable: true,
      renderCell: (params) => (
        <div
          style={{
            backgroundColor: `${params.value}`,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#2E2E2E",
            fontWeight: "bold",
            padding: "8px",
            boxSizing: "border-box",
          }}
        >
          {params.value}
        </div>
      ),
    },

    {
      field: "delivered",
      headerName: "Delivered",
      width: 80,
      editable: true,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 170,
      editable: true,
      valueGetter: (params) => formatDate(params.value),
    },
  ];

  columns = props.canChangeStatus
    ? // if inventory, add column with updatable admin status
      [
        ...columns,
        {
          field: "adminStatus",
          headerName: "Admin Status",
          width: 150,
          editable: true,
          renderCell: (params) => (
            <Select
              value={params.row.adminStatus}
              onChange={async (e) => {
                console.log(params);
                await updateAdminStatus(
                  params.row.id,
                  params.row.orderId,
                  e.target.value
                );
                await getOrders(true);
              }}
              style={{
                backgroundColor:
                  params.row.adminStatus === AdminStatusEnum.Shipped
                    ? "rgba(77, 74, 191, 0.5)"
                    : params.row.adminStatus === AdminStatusEnum.Packed
                    ? "rgba(253, 177, 102, 0.5)"
                    : params.row.adminStatus === AdminStatusEnum.Received
                    ? "rgba(204, 255, 229, 0.5)"
                    : params.row.adminStatus === AdminStatusEnum.Returned
                    ? "rgba(255, 200, 195, 0.5)"
                    : "",
              }}
            >
              {orderAdminStatuses
                .filter((status) => status.name !== "All")
                .map((status) => (
                  <MenuItem value={status.name}>{status.name}</MenuItem>
                ))}
            </Select>
          ),
        },
      ]
    : // if orders, add column with non-updatable admin status
      [
        ...columns,
        {
          field: "adminStatus",
          headerName: "Admin Status",
          width: 100,
          editable: true,
        },
      ];

  const getOrders = async (getCount: boolean, currentPageUpdated?: boolean) => {
    let url = `/owner/order-item`;

    let queryStrings: string[] = [];

    if (props.customerId) {
      url = `/owner/order-item`;
      queryStrings.push(`customerId=${props.customerId}`);
    } else if (props.transactionId) {
      queryStrings.push(`orderTransaction=${props.transactionId}`);
    } else if (props.vendorTransactionId) {
      queryStrings.push(`vendorTransaction=${props.vendorTransactionId}`);
    }

    await OrderService.getItems({
      status: status,
      statuses: orderAdminStatuses,
      getCount: getCount,
      setCount: setCount,
      items: items,
      setItems: setItems,
      httpClient: httpClient,
      dataGridApiRef: dataGridApiRef,
      requestUrl: url,
      queryStrings: queryStrings,
      requestMethod: "post",
      responseField: "content",
    });
  };

  const updateAdminStatus = async (
    orderItemId: string,
    orderId: string,
    adminStatus: string
  ) => {
    await OrderService.updateItem({
      httpClient: httpClient,
      requestUrl: `/owner/order-item/status`,
      requestMethod: "put",
      requestBody: {
        orderItemId: orderItemId,
        orderId: orderId,
        adminStatus: adminStatus,
      },
      queryStrings: [`id=${orderItemId}`],
    });
  };

  return (
    // show the main Orders page

    <BasePage
      snackBarErrorClients={[
        httpClient,
        getCustomerUserBasicDetailsHttpClient,
        getVendorBasicDetailsHttpClient,
        getOrderItemBasicDetailsHttpClient,
        getOrderBasicDetailsClient,
        getOrderTransactionBasicDetailsClient
      ]}
    >
      <GenericPopup
        open={openCustomerUserBasicDetailsPopup}
        setOpen={setOpenCustomerUserBasicDetailsPopup}
        data={customerUserBasicDetailsData || {}}
      />

      <GenericPopup
        open={openVendorBasicDetailsPopup}
        setOpen={setOpenVendorBasicDetailsPopup}
        data={vendorBasicDetailsData || {}}
      />

      <GenericPopup
        open={openOrderItemBasicDetailsPopup}
        setOpen={setOpenOrderItemBasicDetailsPopup}
        data={orderItemBasicDetailsData || {}}
      />

      <GenericPopup
        open={openOrderBasicDetailsPopup}
        setOpen={setOpenOrderBasicDetailsPopup}
        data={orderBasicDetailsData || {}}
      />

      <GenericPopup
        open={openOrderTransactionBasicDetailsPopup}
        setOpen={setOpenOrderTransactionBasicDetailsPopup}
        data={orderTransactionBasicDetailsData || {}}
      />

      <GenericPopup
        open={openCategoryBasicDetailsPopup}
        setOpen={setOpenCategoryBasicDetailsPopup}
        data={categoryBasicDetailsData || {}}
      />

      <GenericPopup
        open={openProductBasicDetailsPopup}
        setOpen={setOpenProductBasicDetailsPopup}
        data={productBasicDetailsData || {}}
      />

      <ImagePopup
        open={openImageModal}
        setOpen={setOpenImageModal}
        imageSrc={imageModalSrc}
      />

      <div
        style={{
          display: "flex",
          gap: "16px",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {props.returnToOriginalComponent && (
          // if Orders has been rendered from other component (e.g. Customers), display arrow to return to original component
          <FaArrowLeft onClick={props.returnToOriginalComponent} />
        )}

        <p style={{ marginBottom: 0 }} className="pageTitle">
          {props.canChangeStatus ? "Inventory" : "Orders"}
        </p>
      </div>

      {props.canChangeStatus ? (
        // if displaying Inventory, show action buttons
        <div
          style={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {orderAdminStatuses.map((object, i) => (
            <CustomButton
              key={i}
              text={object.name}
              onClick={() => {
                setCount(0);
                setItems([]);
                setStatus(i);
                dataGridApiRef.current.setPage(0);
              }}
              isSecondary={status === i ? false : true}
            />
          ))}
        </div>
      ) : (
        // otherwise, do not display action buttons
        <p></p>
      )}

      <SearchBar
        fetchDataById={getSearchBarItemById}
        searchBarResults={searchBarResults}
        setSearchBarResults={setSearchBarResults}
      />

      {/* <div style={{ display: "flex", gap: "10px" }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FileDownloadIcon />}
          style={{ width: "200px" }}
          onClick={async () => {
            await OrderService.exportToCSV({httpClient: httpClient});
          }}
        >
          Export to CSV
        </Button>
      </div> */}

      <div style={{ display: "flex", gap: "10px" }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FileDownloadIcon />}
          style={{ width: "200px" }}
          onClick={async () => {
            await OrderService.exportToExcel({ httpClient: httpClient });
          }}
        >
          Export to Excel
        </Button>
      </div>

      <TableList
        // key={props.canChangeStatus.toString()}
        rows={searchBarResults.length > 0 ? searchBarResults : items}
        isLoading={httpClient.isLoading || httpClient.isLoading}
        columns={columns}
        rowsCount={count}
        getItems={(p) => getOrders(false)}
        dataGridApiRef={dataGridApiRef}
        showRowNumbers
        rowNumbersDescending
      />
    </BasePage>
  );
};

export default OrderList;
