import react, { useEffect, useState } from "react";
import PopupBase from "../PopupBase/PopupBase";
import { useHttpClient } from "../../Hooks/HttpHook";
import SnackbarErrorClient from "../SnackbarError/SnackbarErrorClient";
import useMultipleImageUploadManager from "../../Hooks/useMultipleImageUploadManager";
import imageCompression from "browser-image-compression";
import { Box, Button, DialogContent, Typography } from "@mui/material";
import {
  ConnectingAirportsOutlined,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import PageLoader from "../PageLoader";
import useSingleImageUploadManager from "../../Hooks/useSingleImageUploadManager";
import useAltMultipleImageUploadManager from "../../Hooks/useAltMultipleImageUploadManager";
import SingleImageUpload from "../SingleImageUpload/SingleImageUpload";

interface SingleImageUploadPopupProps {
  open: any;
  setOpen: any;
  item: any;
  getItems: Function;
  uploadImagesRequestUrl: string;
  uploadImagesRequestMethod: string;
  getExistingImagesRequestUrl?: string;
  getExistingImagesRequestMethod?: string;
  deleteImageRequestUrl?: string;
  deleteImageRequestMethod?: string;
}

const SingleImageUploadPopup: React.FunctionComponent<
SingleImageUploadPopupProps
> = (props) => {
  
  let resetCropState = () => {};

  const { 
    image,
    setImage,
    existingImages,
    setExistingImages,
    aspectRatio,
    httpClient,
    setError,
    imageSrc,
    setImageSrc,
    initImageSrc,
    getExistingImages,
    handleDeleteImage
  } = useAltMultipleImageUploadManager({
    getItems: props.getItems,
    uploadImagesRequestUrl: props.uploadImagesRequestUrl,
    uploadImagesRequestMethod: props.uploadImagesRequestMethod,
    getExistingImagesRequestUrl: props.getExistingImagesRequestUrl,
    getExistingImagesRequestMethod: props.getExistingImagesRequestMethod,
    deleteImageRequestUrl: props.deleteImageRequestUrl,
    deleteImageRequestMethod: props.deleteImageRequestMethod
  });

  useEffect(() => {
    if (props.open) {
      setError("");
      setImage({ preview: "", data: "" });
      getExistingImages(props.item?.id);
    }
  }, [props.open]);

  useEffect(() => {
    setImage({ preview: "", data: "" });
    setImageSrc(null);

    return () => {
      setExistingImages([]);
      setImage({ preview: "", data: "" });
      setImageSrc(null);
    };
  }, []);

  // useEffect(() => {
  //   console.log(`useImageUploadManager`);
  //   console.log(`\timages`);
  //   console.log(images);
  //   console.log(imageSrcs);
  // });


  const onSubmit = async () => {
    try {
      const formData = new FormData();

      if (imageSrc != null) {

        const compressedFile = await imageCompression(imageSrc, {
          maxSizeMB: 0.3,
          maxWidthOrHeight: 390,
          useWebWorker: true,
        });

        formData.append("image", compressedFile);
      } else {
        console.log(`SingleImageUploadPopup - imageSrc is null`)
      }

      formData.append("id", props.item?.id);

      console.log(`SingleImageUploadPopup - formData`);
      formData.forEach((value, key) => {
        console.log(`\t${key}: ${value}`);
      });

      const response = await httpClient.sendRequest({
        url: props.uploadImagesRequestUrl,
        method: props.uploadImagesRequestMethod,
        body: formData,
        headers: {
            headers: { "Content-Type": "multipart/form-data" },
            // withCredentials: true
          }
      });

      console.log(response);

      await getExistingImages(props.item?.id);
      setImage({ preview: "", data: "" });
      setImageSrc(null);

      props.getItems();

      resetCropState();
      
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <PopupBase open={props.open} setOpen={props.setOpen}>
      <SnackbarErrorClient client={httpClient} />
      <PageLoader loading={httpClient.isLoading} />

      <h2>Existing Images</h2>

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "80%",
          height: "60%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            justifyContent: "center",
          }}
        >
          {existingImages.map((imageSrc, index) => (
            <div key={index} style={{ position: "relative" }}>
              <img
                src={imageSrc}
                alt={`image-${index}`}
                style={{
                  maxWidth: "200px",
                  maxHeight: "200px",
                  objectFit: "cover",
                }}
              />
              <IconButton
                onClick={() => handleDeleteImage(imageSrc, props.item?.id)}
                style={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  color: "white",
                  backgroundColor: "#4d4abf",
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          ))}
        </div>
      </DialogContent>

      <h2>Add New Images</h2>

      <Box display="flex" justifyContent="center" marginBottom="10px">
        {/* <Button
          variant={aspectRatio === 1 ? "contained" : "outlined"}
          onClick={() => setAspectRatio(1)}
          style={{ marginRight: "10px" }}
        >
          1:1
        </Button> */}
        {/* <Button
          variant={aspectRatio === 16 / 9 ? "contained" : "outlined"}
          onClick={() => setAspectRatio(16 / 9)}
         style={{ marginRight: "10px" }}
        >
          16:9
        </Button> */}
        {/* <Button
          variant={aspectRatio === 4 / 3 ? "contained" : "outlined"}
          onClick={() => setAspectRatio(4 / 3)}
        >
          4:3
        </Button> */}
      </Box>

      

      <SingleImageUpload
        title=""
        img={initImageSrc}
        setImg={setImageSrc}
        aspectRatio={aspectRatio}
      />

      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: "15px" }}
        onClick={onSubmit}
      >
        Submit
      </Button>
      {/* <button onClick={onSubmit}>Submit</button> */}
    </PopupBase>
  );
};

export default SingleImageUploadPopup;
