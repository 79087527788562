import React, { useEffect, useMemo, useState } from "react";
import SnackbarErrorClient from "../../Components/SnackbarError/SnackbarErrorClient";
import { Alert, Box, Modal, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { useHttpClient } from "../../Hooks/HttpHook";
import { ICustomer } from "../../Models/ICustomer";
import { ILocation } from "../../Models/ILocation";
import { IProvince } from "../../Models/IProvince";
import { ICity } from "../../Models/ICity";
import CustomerList from "../../Pages/CustomerList/CustomerList";
import PageLoader from "../../Components/PageLoader";
import { IProduct } from "../../Models/IProduct";
import { IVendor } from "../../Models/IVendor";
import { ICategory } from "../../Models/ICategory";
import CustomButton from "../CustomButton/CustomButton";
import { IReview } from "../../Models/IReview";
import { ReviewStatus } from "../../Data/Enums";

interface reviewProps {
  review: IReview | null;
  reviewId: string;  
  open: any;
  setOpen: any;
  getReviews: Function;

}

const ReviewReview: React.FunctionComponent<
reviewProps
> = (props) => {
  const handleClose = () => props.setOpen(false);
  const reviewClient = useHttpClient();  

  let data = {
    "Rating": props.review?.rating ? props.review?.rating:  "",
    "User Id": props.review?.user ? props.review?.user : "",
    "User Name": props.review?.userName ? props.review?.userName : "",
    "Review": props.review?.review ? props.review?.review : "",
    "Order": props.review?.order ? props.review?.order : "",
    "Order Item": props.review?.orderItem ? props.review?.orderItem : "",
  }



  const updateReviewStatus = async(reviewId:string, status:string)=>{
    try {

    const response = await reviewClient.sendRequest({
      url:'owner/reviews/updateStatus',
      method: "put",
      body:{
        reviewId,
        status,
      },
      headers: {
        headers: { "Content-Type": "application/json" },
      },
    });

    props.getReviews();
    
  } catch (e) {
    console.log(reviewClient.error);
  }
  }
 



  return (
    <div>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="reviewProductBox">
        <PageLoader loading={reviewClient.isLoading} />
          {data && (
            <Table>
              <TableBody>
                {Object.entries(data).map(([key, value]) => (
                  <TableRow key={key}>
                    <TableCell>{key}</TableCell>
                    <TableCell>
                      {typeof value === "object" ? JSON.stringify(value) : value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            
          )}
            <p style={{ marginBottom: 10 }}></p>

            <CustomButton
            
                text={"Accept"}
                onClick={() => {
                    updateReviewStatus(props.reviewId, ReviewStatus.Accepted);
                    handleClose();
                }
                }
              />
            <p style={{ marginBottom: 10 }}></p>

              <CustomButton
                text={"Reject"}
                isSecondary={true}
                onClick={() => {
                    updateReviewStatus(props.reviewId, ReviewStatus.Rejected);
                    handleClose();
                }
                }
              />
        </Box>
      </Modal>
    </div>
  );
};

export default ReviewReview;
