import { SetStateAction } from "react";
import ApiService from "./ApiService";

export default class VendorService extends ApiService {
  static async getVendorLocation({
    httpClient,
    vendorId,
    setVendorLocationData,
  }: {
    httpClient: any;
    vendorId: string;
    setVendorLocationData: React.Dispatch<SetStateAction<{}>>;
  }) {
    try {
      const response = await httpClient.sendRequest({
        url: `/owner/vendorlocation?vendorId=${vendorId}`,
        method: "get",
        headers: {
          headers: { "Content-Type": "application/json" },
          // withCredentials: true
        },
      });

      console.log(`Fetching location data for vendor with ID ${vendorId}`);

      console.log(response);

      if (response) {
        let modifiedData = {
          Street: response.street ?? "",
          Province: response.provinceName ?? "",
          "Province AR": response.provinceNameAr ?? "",
          City: response.cityName ?? "",
          "City AR": response.cityNameAr ?? "",
          Building: response.building ?? "",
          Floor: response.floor ?? "",
          Apartment: response.apartment ?? "",
          "City ABS ID": response.cityAbsCityId ?? "",
          "City Location Code": response.cityLocationCode ?? "",
          "City Runner ID": response.cityRunnerId ?? "",
          Latitude: response.latitude ?? "",
          Longitude: response.longitude ?? "",
        };

        setVendorLocationData(modifiedData);
      }
    } catch (error) {
      console.log(error);
    }
  }

  static async getVendorUserInformation({
    httpClient,
    vendorUserId,
    setVendorUserData,
  }: {
    httpClient: any;
    vendorUserId: string;
    setVendorUserData: React.Dispatch<React.SetStateAction<{}>>;
  }) {
    try {
      const response = await httpClient.sendRequest({
        url: `/owner/vendor/vendoruser-info/${vendorUserId}`,
        method: "get",
        body: null,
      });

      if (response) {
        console.log(response);

        let modifiedData = {
          ID: response.id ?? "",
          "First Name": response.firstName ?? "",
          "Last Name": response.lastName ?? "",
          "Phone Number": response.phoneNumber ?? "",
          Email: response.email ?? "",
          "National ID": response.nationalId ?? "",
          "Account Type": response.accountType?.name ?? "",
          Verified: response.verified ?? "",
          Vendors: "",
        };

        if (response.vendors && Array.isArray(response.vendors)) {
          let baseString = "";

          const vendors = response.vendors.map(
            (vendor: any) => baseString + (vendor.name ?? "") + ", "
          );

          modifiedData["Vendors"] = vendors;
        }

        console.log(modifiedData);

        setVendorUserData(modifiedData);
      }
    } catch (error) {
      console.log(error);
    }
  }
}