const ProductStatus = {
  Accepted: "accepted",
  Pending: "pending",
  Rejected: "rejected",
};


const VendorStatus = {
  All: "All",
  Accepted: "accepted",
  Pending: "pending",
  Rejected: "rejected",
};

const SearchBarEnum = {
  ID: "id",
  Name: "name"
};


type HomeSectionType = "products" | "vendors";
type SearchByType = "id" | "name";


  const ReviewStatus = {
    Accepted: "Accepted",
    Pending: "Pending",
    Rejected: "Rejected",
  };

  // equivalent of AdminStatusEnum on backend
  const AdminStatusEnum = {
    Pending: "Pending",
    Received: "Received",
    Packed: "Packed",
    Shipped: "Shipped",
    Returned: "Returned"
  }

  // equivalent to VendorTransactionFilterEnum in controller/Owner/VendorTransaction.ts
  const VendorTransactionFilterEnum = {
    Paid: "Paid",
    NotPaid: "Not Paid",
    Returned: "Returned",
  }

  enum OrderListType{
    all, customer, transaction
  }

  const BackendType = {
    NODE: "NODE",
    JAVA: "JAVA"
  }

export {
  ReviewStatus,
  ProductStatus,
  VendorStatus,
  AdminStatusEnum,
  OrderListType,
  VendorTransactionFilterEnum,
  BackendType
};

export type { 
  HomeSectionType,
  SearchByType,
};
